import React, { useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";

function NotificationModal({
  open,
  onClose,
  iconType,
  title,
  message,
  onAcceptLabel,
  onRefuseLabel,
  onContinueLabel,
  onAccept,
  onRefuse,
  onContinue,
}) {
  // Función para determinar el ícono a mostrar
  const renderIcon = () => {
    switch (iconType) {
      case "success":
        return (
          <Box
            sx={{
              display: "flex",
              width: Spaces.Size_10,
              height: Spaces.Size_10,
              padding: Spaces.Size_2,
              justifyContent: "center",
              alignItems: "center",
              background: Colors.Success_50,
              borderRadius: Spaces.Size_8,
            }}
          >
            <span
              style={{ color: Colors.Success_700, fontSize: Spaces.Size_6 }}
              className="material-symbols-outlined"
            >
              check
            </span>
          </Box>
        );
      case "warning":
        return (
          <Box
            sx={{
              display: "flex",
              width: Spaces.Size_10,
              height: Spaces.Size_10,
              padding: Spaces.Size_2,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: Spaces.Size_8,
            }}
          >
            <span
              style={{ color: Colors.Warning_600, fontSize: Spaces.Size_10 }}
              className="material-symbols-outlined"
            >
              warning
            </span>
          </Box>
        );
      case "error":
        return (
          <Box
            sx={{
              display: "flex",
              width: Spaces.Size_10,
              height: Spaces.Size_10,
              padding: Spaces.Size_2,
              justifyContent: "center",
              alignItems: "center",
              background: Colors.Error_50,
              borderRadius: Spaces.Size_8,
            }}
          >
            <span
              style={{ color: Colors.Error_700, fontSize: Spaces.Size_6 }}
              className="material-symbols-outlined"
            >
              sentiment_dissatisfied
            </span>
          </Box>
        );
      case "info":
        return (
          <Box
            sx={{
              display: "flex",
              width: Spaces.Size_10,
              height: Spaces.Size_10,
              padding: Spaces.Size_2,
              justifyContent: "center",
              alignItems: "center",
              background: Colors.Error_50,
              borderRadius: Spaces.Size_8,
            }}
          >
            <span
              style={{ color: Colors.Info_800, fontSize: Spaces.Size_6 }}
              className="material-symbols-outlined"
            >
              info
            </span>
          </Box>
        );
      default:
        return null;
    }
  };

  const handleOnAccept = () => {
    if (onAccept) {
      onAccept();
    }
    onClose();
  };

  const handleOnRefuse = () => {
    if (onRefuse) {
      onRefuse();
    }
    onClose();
  };

  const handleOnContinue = () => {
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="notification-modal-title"
      aria-describedby="notification-modal-description"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: Spaces.Size_10,
          alignItems: "center",
          gap: Spaces.Size_9,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: '90%',   // 100% width on extra-small screens
            sm: '415px',    // 50% width on small screens
            md: '415px',    // 33% width on medium screens
          },
          borderRadius: Spaces.Size_4,
          background: "white",
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            display: "inline-block",
            position: "absolute",
            top: "20px",
            right: "24px",
            padding: "0px",
            minWidth: "auto",
          }}
        >
          <span
            style={{ color: Colors.Secondary_900, fontSize: Spaces.Size_6 }}
            className="material-symbols-outlined"
          >
            close
          </span>
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: Spaces.Size_5,
          }}
        >
          <div>{renderIcon()}</div>

          <Typography
            id="notification-modal-title"
            sx={{
              fontFamily: TextVariables.Subtitle_4,
              lineHeight: "1",
              textAlign: "center",
            }}
          >
            {title}
          </Typography>

          <Typography
            id="notification-modal-description"
            sx={{
              fontFamily: TextVariables.Body_4,
              padding: "0px",
              maxWidth: "100%",
              textAlign: "center",
            }}
          >
            {message}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: Spaces.Size_5,
          }}
        >
          {onRefuseLabel !== undefined && (
            <Button
              onClick={handleOnRefuse}
              sx={{
                border: `1px solid ${Colors.Secondary_900}`,
                backgroundColor: "white",
                fontFamily: TextVariables.Caption_1_Bold,
                lineHeight: "1",
                color: Colors.Secondary_900,
                textTransform: "none",
                display: "flex",
                padding: Spaces.Size_4,
                justifyContent: "center",
                alignItems: "center",
                gap: Spaces.Size_2,
                borderRadius: Spaces.Size_2,
                "&:hover": {
                  backgroundColor: Colors.Secondary_50, // Cambia el color al hacer hover
                  opacity: 0.8, // Aplica opacidad al hacer hover
                },
              }}
            >
              {onRefuseLabel}
            </Button>
          )}
          {onAcceptLabel !== undefined && (
            <Button
              onClick={handleOnAccept}
              sx={{
                backgroundColor: Colors.Primary_700,
                fontFamily: TextVariables.Caption_1_Bold,
                lineHeight: "1",
                color: "white",
                textTransform: "none",
                display: "flex",
                padding: Spaces.Size_4,
                justifyContent: "center",
                alignItems: "center",
                gap: Spaces.Size_2,
                borderRadius: Spaces.Size_2,
                "&:hover": {
                  backgroundColor: "#FF0000", // Cambia el color al hacer hover
                  opacity: 0.8, // Aplica opacidad al hacer hover
                },
              }}
            >
              {onAcceptLabel}
            </Button>
          )}
          {onContinueLabel !== undefined && (
            <Button
              onClick={handleOnContinue}
              sx={{
                backgroundColor: Colors.Primary_700,
                fontFamily: TextVariables.Caption_1_Bold,
                lineHeight: "1",
                color: "white",
                textTransform: "none",
                display: "flex",
                padding: Spaces.Size_4,
                justifyContent: "center",
                alignItems: "center",
                gap: Spaces.Size_2,
                borderRadius: Spaces.Size_2,
                "&:hover": {
                  backgroundColor: "#FF0000", // Cambia el color al hacer hover
                  opacity: 0.8, // Aplica opacidad al hacer hover
                },
              }}
            >
              {onContinueLabel}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default NotificationModal;
