//Singleton script tha contains all the request uses in both main components (Signal and Follower)
import axios from "axios";
import Cookies from "universal-cookie";

//Main path builds depending on the enviroment selected or run by administrator
const cookies = new Cookies();
const url = cookies.get("url");
let token = cookies.get("token");

let baseUrl = `${url}/api`;
let baseUrlShort = `${url}`;

function manejarCookieActualizada(event) {
  baseUrl = `${event.detail.value}/api`;
  baseUrlShort = `${event.detail.value}`;
}

function onUpdateToken(event) {
  token = event.detail.value;
  headers.Authorization = "Bearer " + token;
}

// Agregar un listener para el evento de cookieActualizada
window.addEventListener("updatedUrl", manejarCookieActualizada);
window.addEventListener("tokenUpdatedEvent", onUpdateToken);

let headers = {
  //Headers preparation, the token is get from cookies, the same token obtained when the user log in
  "Content-type": "application/json; charset=UTF-8",
  Authorization: "Bearer " + token,
};

//It's not being used in this moment
const getSettingLogs = async (credentials) => {
  const res = await axios.post(`${baseUrl}/getSlaves`, credentials);
  return res;
};

//Request that brigns the status information about follower or signal providers account
const getStatus = async () => {
  const res = await axios.get(`${baseUrl}/status`, { headers });
  return res;
};

//Request that brigns the information about a signal or follower account
const getInformation = async () => {
  const res = await axios.get(`${baseUrl}/information`, { headers });
  return res;
};

//Request that brigns the information about an specific signal
const getSignalInformation = async (signalLogin) => {
  const res = await axios.get(`${baseUrl}/information/signal?signal_acc=${signalLogin}`, { headers });
  return res;
};

//Request that brigns the information about an specific signal
const getStrategyImage = async (params) => {
  const res = await axios.get(`${baseUrlShort}/${params}`, { headers });
  return res;
};

const getIpClient = async () => {
  const userLocalization = await axios.get('https://one.one.one.one/cdn-cgi/trace');
  const ipMatchV4 = userLocalization.data.match(/ip=([0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)/);
  const ipMatchV6 = userLocalization.data.match(/ip=([a-fA-F0-9:]+)/);

  if (ipMatchV4) {
    const ipV4 = ipMatchV4[1];
    const response = { data: ipV4 };
    return response;
  }
  else if (ipMatchV6) {
    const ipV6 = ipMatchV6[1];
    const response = { data: ipV6 };
    return response;
  }
  else{
    const ipvx = await axios.get('https://api64.ipify.org?format=json');
    const response = {data: ipvx.data.ip};
    return response; 
  }
};

//Request that log out the user logged in that moment
const logOut = async (headers) => {
  const res = await axios.delete(`${baseUrl}/login`, { headers });
  return res;
};

const methods = {
  getSettingLogs,
  getStatus,
  getInformation,
  getSignalInformation,
  getIpClient,
  logOut,
  getStrategyImage,
};

export default methods;
