import LanguageSelector from "../../components/Generals/Selectors/languageSelector.js";
import logo_communitraders from "../../assets/logos/LogoTradeView.svg";
import logo_communitraders_small from "../../assets/logos/CommunitraderSmallLogo.svg";
import { Box } from "@mui/material";
import * as styles from "../NavbarSidebar/Styles.js";
import Background from "../Backgrounds/Background.js";
import MenuBookIcon from '@mui/icons-material/MenuBook';

const NavbarLayout = (props) => {
  return (
    <div>
      <Background></Background>
      <Box sx={styles.navbar}>
        <a href="https://www.tvmarkets.com/">
          <Box
            sx={{
              width: {
                xs: "50px",     
                sm: "220px",
                md: "220px",
              },
              height: {
                xs: "33px",
                sm: "34px",
                md: "34px",
              },
              backgroundImage: {
                xs: `url(${logo_communitraders_small})`,
                sm: `url(${logo_communitraders})`,
                md: `url(${logo_communitraders})`,
              },
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          </a>
          <Box sx={{
            display:'flex',
            justifyContent:'flex-end',
            width: '100%'
          }}>
            <a
              href="https://communitraders-mt4-demo.tradeviewforex.com/documentations/Communitraders-User-Manual.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <MenuBookIcon sx={{color:'#660000', marginRight:'3px', marginBottom: '2px',fontSize: 22}}/> User Manual
            </a>
        </Box>
        <LanguageSelector></LanguageSelector>
      </Box>
      <Box sx={styles.contentLogin}>{props.children}</Box>
    </div>
  );
};

export default NavbarLayout;