//Screen rendered when the user enters a path not found

import "../../css/notFound.scss";
import { useNavigate } from "react-router-dom";
import { baseEnv } from "../../App";

function NotFound(props) {
  //Declaration of constants, variables and hooks
  const navigate = useNavigate();

  // Function to handle the bad routes, this take the user to a default 404 page
  const Home = (e) => {
    navigate(baseEnv + "/");
  };

  return (
    <div className="wrap-error">
      <div className="d-flex align-items-center h-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 offset-sm-2 text-center text-white">
              <h1 className="">
                <span>4</span>
                <span>0</span>
                <span>4</span>
              </h1>
              <h5 className="">Oops! Page not found</h5>
              <p className="mb-4">
                we are sorry, but the page you requested was not found
              </p>
              <button
                className="btn btn-dark"
                href="#"
                title="home url"
                onClick={Home}
              >
                Go home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
