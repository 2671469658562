//Signal settings component render when the user click the setting button in sidebar component
import { useState, useEffect } from "react";
import "../../../css/signal/signalFirstStrategy.scss";
import TitledSelector from "../../Generals/Selectors/TitledSelector";
import { useNavigate } from "react-router-dom";
import signalService from "../../../services/signalProvider";
import TitleInput from "../../Generals/Inputs/TitledInput";
import TitleBigInput from "../../Generals/Inputs/TitledBigInput";
import TableToolTip from "../../Generals/Tooltips/TableToolTip";
import ImageSelector from "../../Generals/Images/circularLoadImage.js";
import { useTranslation } from "react-i18next";
import RedButton from "../../Generals/Buttons/RedButton";
import LoadingSpinner from "../../Generals/Loaders/SpinLoader";
import {
  ddProtectionModes,
  strategyTypes,
  booleanOptions,
} from "../../../Master Data/masterData.js";
import { GetUserInformation } from "../../../Tools/LocalStorage";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useDynamicStyles } from "./Styles";
import { Spaces, mainBox } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import NotificationModal from "../../Generals/Notifications/NotificationModal.js";
import { compressImageToJPEGOrPNG } from "../../../Tools/ImageCompresor";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import StatusModule from "../../Generals/Cards/StatusModule";

function FirstStrategy(props) {
  //This function gets props from sidebar component
  const styles = useDynamicStyles();
  //#region "Instantiation of States"
  const { isXs, isMd } = useBreakpoint();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    //General fields
    strategy_name: false,
    description: false,
    fee_value: false,
    fee_new_value: false,
    drawdown_mode: false,
    max_drawdown: false,
    disable_account_on_drawdown: false,
    strategy_type: false,
    image: false,
  });
  const [formValues, setFormValues] = useState({
    strategy_name: "",
    description: "",
    fee_value: 0,
    fee_new_value: 0,
    drawdown_mode: "",
    max_drawdown: "",
    disable_account_on_drawdown: false,
    strategy_type: "",
    image: "",
    ib_account:"",
  });
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onContinueLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
    onContinue: undefined,
  });

  const [selectedFile, setSelectedFile] = useState(null); // Estado para el archivo seleccionado
  const [strategyInfo, setStrategyInfo] = useState({
    currency: "",
    account_type: "",
    leverage: "",
  });
  //#endregion

  //#region "Declaration of Normal Variables or Constants"
  const [t] = useTranslation("global");
  //#endregion

  //#region "Handlers"

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "strategyName":
        if (value.length <= 30) {
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
          }));
        }
        break;
      case "fee_new_value":
        if (value.length <= 2 && Number(value) <= 50) {
            setFormValues((prevFormValues) => ({
                ...prevFormValues,
                [name]: Number(value),
            }));
        }
        else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            fee_new_value: "The value of the fee cannot be over 50%",
          }));
        }
        break;
      case "ib_account":
        if (value !== "0" && value !== "") {
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ib_account: "Please, enter a valid IB account number or your account number",
          }));
        }
        break;
      default:
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [name]: value,
        }));
        break;
    }
};

  const handleSubmit = () => {
    let valid = true;
    let newErrors = {};

    // Verificar cada campo
    for (const [key, value] of Object.entries(formValues)) {
      if (
        (value === "" || value === false) &&
        key !== "image" &&
        key !== "visibility" &&
        key !== "disable_account_on_drawdown"
      ) {
        newErrors[key] = true;
        valid = false;
      } else {
        newErrors[key] = false;
      }
    }

    setErrors(newErrors);

    if (valid) {
      UpdateUserStrategy();
    } else {
    }
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };

  // Recibe la imagen seleccionada desde el ImageSelector
  const handleImageSelect = async (file) => {
    try {
      // Llamar a la función para comprimir y convertir la imagen
      const compressedImage = await compressImageToJPEGOrPNG(
        file,
        0.7,
        "image/jpeg"
      );
      setSelectedFile(compressedImage);
    } catch (error) {
      setSelectedFile(file);
    }
  };

  // Lógica para subir la imagen al hacer clic en "Upload Photo"
  const handleUpload = async () => {
    if (!selectedFile) {
      setModalProps({
        open: true,
        iconType: "error",
        title: "Please, select an strategy image first",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
      return;
    }
    const renamedFile = new File([selectedFile], "test.jpeg", {
      type: selectedFile.type,
    });

    const formData = new FormData();
    formData.append("file", renamedFile); // Asegúrate de que 'file' sea correcto en tu backend

    try {
      setLoading(true);
      const response = await signalService.SetProfilePictureRequest(formData);
      if (response.data != null) {
        setLoading(false);
        const { data } = response.data;
        setFormValues((prevValues) => ({
          ...prevValues,
          image: data,
        }));
        setModalProps({
          open: true,
          iconType: "success",
          title: "Strategy image loaded successfully",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      } else {
        setLoading(false);
        setModalProps({
          open: true,
          iconType: "error",
          title: "Error loading strategy image",
          message:
            "An error uploading the strategy image has occurred. Please try again, if the error persists contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      }
    } catch (error) {
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Error loading strategy image",
        message:
          "An error uploading the strategy image has occurred. Please try again, if the error persists contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
      console.log(error);
    }
  };
  //#endregion

  //#region "Requests"
  const GetLocalStorageData = () => {
    let dataResponse = GetUserInformation();
    if (dataResponse.dataExits) {
      let strategyName =
        dataResponse.data.account === dataResponse.data.strategy_name
          ? ""
          : dataResponse.data.strategy_name;
      setFormValues({
        strategy_name: strategyName,
        description: dataResponse.data.description,
        image: dataResponse.data.image,
        strategy_type: dataResponse.data.strategy_type,
        fee_value: dataResponse.data.fee_value,
        fee_new_value: dataResponse.data.fee_new_value,
        drawdown_mode: dataResponse.data.drawdown_mode,
        max_drawdown: dataResponse.data.max_drawdown,
        disable_account_on_drawdown:
          dataResponse.data.disable_account_on_drawdown,
        ib_account: dataResponse.data.ib_account
      });
      setStrategyInfo(dataResponse.data);
      return true;
    } else {
      return false;
    }
  };
  const UpdateUserStrategy = async () => {
    try {
      setLoading(true);
      const response = await signalService.editMaster(formValues);
      if (response.data != null) {
        setLoading(false);
        navigate("/SignalProvider/Profile");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (
        error.response.data.message ===
        "The strategy name already exists in Communitraders."
      ) {
        setModalProps({
          open: true,
          iconType: "error",
          title: "Error with strategy name",
          message:
            "The strategy name is already in use, please try changing it and try again.",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      } else {
        setModalProps({
          open: true,
          iconType: "error",
          title: "Server error",
          message:
            "An unexpected error has occurred, please contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      }
    }
    setLoading(false);
  };
  //#endregion

  //#region "useEffects"
  useEffect(() => {
    if (!GetLocalStorageData()) {
    }
  }, []);
  //#endregion

  //#region "HTML"
  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <NotificationModal
        open={modalProps.open}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onAccept={modalProps.onAccept}
        onRefuseLabel={modalProps.onRefuseLabel}
        onRefuse={modalProps.onRefuse}
        onContinueLabel={modalProps.onContinueLabel}
        onContinue={modalProps.onContinue}
      />
      <Box sx={styles.createStrategyBox}>
        {isXs && <StatusModule></StatusModule>}

        <Box sx={styles.strategySettingsTitleBox}>
          <Typography sx={{ fontFamily: TextVariables.Subtitle_4 }}>
            {t("signalProvider.My profile")}
          </Typography>
          <Typography sx={{ fontFamily: TextVariables.Body_4 }}>
            {t(
              "signalProvider.Set up your strategy's profile and settings. This will serve as your introduction card to engage potential followers"
            )}
          </Typography>
        </Box>
        <Paper sx={styles.settingFormContainer}>
          <Box sx={styles.generalInformationContainer}>
            <Box sx={styles.settingFormTitle}>
              <Box sx={styles.circularNumber}>1</Box>
              <Typography sx={{ fontFamily: TextVariables.Body_4_Bold }}>
                {t("signalProvider.General information")}
              </Typography>
            </Box>
            <TitleInput
              name="strategy_name"
              title={t("signalProvider.Strategy name")}
              value={formValues.strategy_name}
              type="text"
              handleChange={handleChange}
              error={errors.strategy_name}
              placeholder={t("signalProvider.Please, enter a strategy name")}
              helperText={
                errors.strategy_name ? (
                  <Box sx={styles.emptyFieldLabel}>
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: Spaces.Size_3, color: "red" }}
                    >
                      error
                    </span>
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Caption_1,
                        color: Colors.Error_700,
                      }}
                    >
                      {t("signalProvider.Please, enter a strategy name")}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )
              }
            ></TitleInput>
            <TitleBigInput
              name="description"
              title={t("signalProvider.Strategy description")}
              value={formValues.description}
              type="text"
              handleChange={handleChange}
              error={errors.description}
              placeholder={t("signalProvider.Please, enter a strategy name")}
              maxChars={300}
              helperText={
                errors.description ? (
                  <Box sx={styles.emptyFieldLabel}>
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: Spaces.Size_3, color: "red" }}
                    >
                      error
                    </span>
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Caption_1,
                        color: Colors.Error_700,
                      }}
                    >
                      {t("signalProvider.Please, enter a strategy description")}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )
              }
            ></TitleBigInput>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Box>
                <Typography sx={styles.strategyImageTitle}>
                  Strategy image{" "}
                  <TableToolTip title="Upload a picture that represents your strategy type, such as a headshot, logo, or chart, to make your profile stand out and attract more followers">
                    <span
                      style={{ fontSize: 12 }}
                      className="material-symbols-outlined"
                    >
                      {t("signalProvider.help")}
                    </span>
                  </TableToolTip>
                </Typography>

                <Box sx={styles.strategyPhotoContainer}>
                  {/* Componente ImageSelector para seleccionar la imagen */}
                  <ImageSelector onImageSelect={handleImageSelect} PrevImage={formValues.image} />

                  {/* Botón que sube la imagen */}
                  <Button sx={styles.uploadPhotoButton} onClick={handleUpload}>
                    {t("signalProvider.Upload Photo")}
                  </Button>
                </Box>
              </Box>
              

              <Box sx={styles.ibAccountContainer}>
                  <TitleInput
                    name="ib_account"
                    title={t("signalProvider.IB Account Number")}
                    value={formValues.ib_account}
                    type="number"
                    handleChange={handleChange}
                    error={errors.ib_account}
                    tooltext="An IB (Introducing Broker) account number is a unique identifier assigned to an IB to track their referrals and ensure they receive commission payments for client trades. ( It can't be zero )"
                    placeholder={t("signalProvider.Enter IB account number")}
                    helperText={
                      errors.ib_account ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            {t("signalProvider.Please, enter a valid IB account number")}
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  />
            </Box>
            </Box>
            
          </Box>
          <Box sx={styles.generalInformationContainer}>
            <Box sx={styles.settingFormTitle}>
              <Box sx={styles.settingsValuesBox}>
                <Box sx={styles.circularNumber}>2</Box>
                <Typography sx={{ fontFamily: TextVariables.Body_4_Bold }}>
                  Strategy settings
                </Typography>
              </Box>
              <Box sx={styles.settingsTitleBox}>
                <Box sx={styles.strategySettingsLabels}>
                  <Typography sx={styles.settingTitle}>
                    {t("signalProvider.Currency")}:
                  </Typography>
                  <Typography sx={styles.valueSettingTitle}>
                    {strategyInfo.currency}
                  </Typography>
                </Box>

                <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                <Box sx={styles.strategySettingsLabels}>
                  <Typography sx={styles.settingTitle}>
                    {t("signalProvider.Leverage")}:
                  </Typography>
                  <Typography sx={styles.valueSettingTitle}>
                    {strategyInfo.leverage}
                  </Typography>
                </Box>
                <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                <Box sx={styles.strategySettingsLabels}>
                  <Typography sx={styles.settingTitle}>
                    {t("signalProvider.Account type")}:
                  </Typography>
                  <Typography sx={styles.valueSettingTitle}>
                    {strategyInfo.account_type}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={styles.strategyTypeFeeBox}>
              <Box flex={1}>
                <TitledSelector
                  name="strategy_type"
                  title={t("signalProvider.Strategy type")}
                  type="text"
                  handleChange={handleChange}
                  placeholder={t("signalProvider.Select an Option.")}
                  options={strategyTypes}
                  error={errors.strategy_type}
                  value={formValues.strategy_type}
                  helperText={
                    errors.strategy_type ? (
                      <Box sx={styles.emptyFieldLabel}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: Spaces.Size_3, color: "red" }}
                        >
                          error
                        </span>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1,
                            color: Colors.Error_700,
                          }}
                        >
                          {t("signalProvider.Please, select an strategy type")}
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                ></TitledSelector>
              </Box>

              <Box flex={1}>
                <TitleInput
                  name="fee_new_value"
                  title={t("signalProvider.Fee (%)")}
                  type="number"
                  handleChange={handleChange}
                  error={errors.fee_new_value}
                  placeholder={t("signalProvider.Please, enter a fee amount")}
                  value={formValues.fee_new_value}
                  inputProps={{
                    min: 0,
                    max: 50
                  }}
                  helperText={
                    errors.fee_new_value ? (
                      <Box sx={styles.emptyFieldLabel}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: Spaces.Size_3, color: "red" }}
                        >
                          error
                        </span>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1,
                            color: Colors.Error_700,
                          }}
                        >
                          {t("signalProvider.Please, enter a fee amount below 50%")}
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                ></TitleInput>
              </Box>
            </Box>
            <TitledSelector
              name="drawdown_mode"
              title={t("signalProvider.Drawdown protection")}
              type="text"
              handleChange={handleChange}
              placeholder="Select a drawdown protection mode"
              options={ddProtectionModes}
              error={errors.drawdown_mode}
              value={formValues.drawdown_mode}
              helperText={
                errors.drawdown_mode ? (
                  <Box sx={styles.emptyFieldLabel}>
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: Spaces.Size_3, color: "red" }}
                    >
                      error
                    </span>
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Caption_1,
                        color: Colors.Error_700,
                      }}
                    >
                      {t("signalProvider.Please, select a max drawdown amount")}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )
              }
            ></TitledSelector>
            {(formValues.drawdown_mode === "InEquity" || formValues.drawdown_mode === "InBalance" )&& (
              <Box sx={styles.strategyTypeFeeBox}>
                <Box flex={1}>
                  <TitleInput
                    name="max_drawdown"
                    title={t("signalProvider.Drawdown Amount")}
                    type="number"
                    handleChange={handleChange}
                    error={errors.max_drowdown}
                    placeholder={t(
                      "signalProvider.Please, select a max drawdown amount"
                    )}
                    value={formValues.max_drawdown}
                    helperText={
                      errors.max_drawdown ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            {t(
                              "signalProvider.Please, select a max drawdown amount"
                            )}
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitleInput>
                </Box>
                <Box flex={1}>
                  <TitledSelector
                    name="disable_account_on_drawdown"
                    title="Disable on drawdown"
                    handleChange={handleChange}
                    error={errors.disable_account_on_drawdown}
                    placeholder="Please, select an option"
                    options={booleanOptions}
                    value={formValues.disable_account_on_drawdown}
                    helperText={
                      errors.disable_account_on_drawdown ? (
                        <Box sx={styles.emptyFieldLabel}>
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          <Typography
                            sx={{
                              fontFamily: TextVariables.Caption_1,
                              color: Colors.Error_700,
                            }}
                          >
                            Please, enter a max drawdown amount
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  ></TitledSelector>
                </Box>
              </Box>
            )}
            <Box sx={styles.submitBox}>
              <RedButton HandleOnClick={handleSubmit}>
                {t("signalProvider.Save")}
              </RedButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
  //#endregion
}

export default FirstStrategy;
