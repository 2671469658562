// LoadingSpinner.jsx
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LoadingSpinner = () => {
  return (
    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh" // Asegura que ocupe toda la altura de la pantalla
    position="fixed" // Fija la posición para mantener el spinner en la vista
    top={0}
    left={0}
    right={0}
    bottom={0}
    bgcolor="rgba(255, 255, 255, 0.4)" // Fondo semi-transparente para efecto de overlay
    zIndex={9999} // Asegura que esté por encima de otros contenidos
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingSpinner;