// encryption.js
import CryptoJS from 'crypto-js';

// Clave secreta utilizada para encriptar y desencriptar
const secretKey = '!74rtg5hy#85df@';

export const encrypt = (data) => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

export const decrypt = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

// Función para hacer que el Base64 sea seguro para URLs
export const makeUrlSafe = (base64String) => {
  return base64String
    .replace(/\+/g, '-')  // Reemplaza '+' con '-'
    .replace(/\//g, '_')  // Reemplaza '/' con '_'
    .replace(/=+$/, '');  // Remueve '=' de padding
};

// Función para revertir el proceso URL-safe
export const revertUrlSafe = (urlSafeString) => {
  let base64String = urlSafeString
    .replace(/-/g, '+')  // Reemplaza '-' con '+'
    .replace(/_/g, '/'); // Reemplaza '_' con '/'
  // Rellena '=' si es necesario para que sea un string Base64 válido
  while (base64String.length % 4) {
    base64String += '=';
  }
  return base64String;
};
