import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {Spaces} from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";

export function SwitchBasic({value, handleChange, disabled = false}) {
  const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: Spaces.Size_9,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 1,
      transform: "translateX(1px)",
      "&.Mui-checked": {
        transform: "translateX(13px)",
        "& + .MuiSwitch-track": {
          backgroundColor: Colors.Success_500,
          opacity: 1,
          border: 0,
        },
        "& .MuiSwitch-thumb": {
          backgroundColor: "white",
          
        },
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
        
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 20,
      height: 20,
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
      backgroundColor: Colors.Error_600,
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleSwitched = (event) => {
    if(event.target.checked === value){
    }
    else{
      handleChange(event);
    }
 
  }
  return <CustomSwitch disabled={disabled} checked={value}  onChange={(event) => (handleSwitched(event))}></CustomSwitch>;
}

export default SwitchBasic;
