import { Button, Typography } from "@mui/material";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { useBreakpoint } from "../../../config/BreakpointContext.js";

function Switch({ HandleOnClick, label, name, selector  }) {
  const { isXs, isMd } = useBreakpoint();
  return (
    <Button
      onClick={HandleOnClick}
      sx={{
        color: "black",
        textTransform: "none",
        padding: `${Spaces.Size_3} 0`,
        gap: Spaces.Size_8,
        alignItems: "center",
        borderBottom: name === selector ? `4px solid ${Colors.Secondary_900}` : `0px solid ${Colors.Secondary_900}`,
        "&:hover": {
          opacity: 0.8, // Aplica opacidad al hacer hover
        },
      }}
    >
      <Typography sx={{fontFamily: isMd ? TextVariables.Body_4 : TextVariables.Link_4, lineHeight: 1}}>{label}</Typography>
    </Button>
  );
}

export default Switch;
