import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { BorderBottom } from "@mui/icons-material";
import { useBreakpoint } from "../../../config/BreakpointContext.js";


export const useDynamicStyles = () => {
  const { isXs, isMd } = useBreakpoint();
  return{
    searchContainer : {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: Spaces.Size_2,
    },
    
    tooltipBox : {
      display: "flex",
      flexDirection: "row",
      gap: Spaces.Size_1,
      alignItems: "center",
      justifyContent: "center",
    },
    
    performanceBox : {
      display: "flex",
      width: "100%",
      flex: "1 0 0",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: Spaces.Size_5,
    },
  }
}

