import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import * as Encryptor from "../Tools/AESEncrypter";
import userService from "../services/user.js"

function ShareIntermediary(props) {
  const navigate = useNavigate();
  const { id } = useParams();

  const RequestInformationData = async () => {
    try {
      localStorage.removeItem('shared_signal_link');
      const response = await userService.getInformation();
      if (response.status == 200) {  
        if(response.data != null && response.data.data.role && response.data.data.role.length > 0){
          if(response.data.data.role === "follower"){
            const signalFollowed = Encryptor.decrypt(id);
            if(response.data.data.signal_followed === signalFollowed){
              navigate("/Follower/FollowingSettings");
              return;
            }
            else{
              navigate("/Follower/Discover/Strategy/" + id);
              return;
            }
          }
          else{
            navigate("/SignalProvider/Profile");
            return;
          }
        }  
      }
    } catch (error) {
      if(error.status == null){
        localStorage.setItem("shared_signal_link", id);
      }
      else if(error.status === 404){
        navigate("/Follower/Discover/Strategy/" + id);
        return;
      }
      else{
        console.log(error.status);
      }     
    }
  };

  useEffect(() => {
    const cookies = new Cookies();
    RequestInformationData();
    const rol = cookies.get("account type");
    



  }, []);

}

export default ShareIntermediary;