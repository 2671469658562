import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import { Padding } from "@mui/icons-material";

export const mainContainer = {
  display: "flex",
  width: "100%",
  flexDirection: {
    xs: "column", // 100% width on extra-small screens
    sm: "column", // 50% width on small screens
    md: "row",
  }, // 33% width on medium screens,
  justifyContent: {
    xs: "center", // 100% width on extra-small screens
    sm: "center", // 50% width on small screens
    md: "center",
  },
  gap: {
    xs: Spaces.Size_9, // 100% width on extra-small screens
    sm: Spaces.Size_9, // 50% width on small screens
    md: "71px",
  },
};

const marginTopValue = `calc(68px + ${Spaces.Size_8} + ${Spaces.Size_13})`;
const marginTopValueSmallScreen = `calc(68px + ${Spaces.Size_1})`;
export const logoContainer = {
  display: "flex",
  flexDirection: "column",
  gap: "42px",
  width: {
    xs: "100%", // 100% width on extra-small screens
    sm: "100%", // 50% width on small screens
    md: "470px",
  },
  alignItems: {
    xs: "center", // 100% width on extra-small screens
    sm: "center", // 50% width on small screens
    md: "flex-start",
  },
  marginTop: {
    xs: marginTopValueSmallScreen, // 100% width on extra-small screens
    sm: marginTopValueSmallScreen, // 50% width on small screens
    md: marginTopValue,
  },
};

export const logoTitle = {
  fontFamily: {
    xs: TextVariables.Body_2_Bold.fontFamily, // Pantallas pequeñas
    sm: TextVariables.Body_2_Bold.fontFamily,
    md: TextVariables.H3.fontFamily, // Pantallas medianas y más grandes
  },
  fontSize: {
    xs: TextVariables.Body_2_Bold.fontSize, // Pantallas pequeñas
    sm: TextVariables.Body_2_Bold.fontSize,
    md: TextVariables.H3.fontSize, // Pantallas medianas y más grandes
  },
  fontWeight: {
    xs: TextVariables.Body_2_Bold.fontWeight, // Pantallas pequeñas
    sm: TextVariables.Body_2_Bold.fontWeight,
    md: TextVariables.H3.fontWeight, // Pantallas medianas y más grandes
  },
  lineHeight: "1",
  textAlign: {
    xs: "center", // 100% width on extra-small screens
    sm: "center", // 50% width on small screens
    md: "left",
  },
  padding: {
    xs: Spaces.Size_2, // 100% width on extra-small screens
    sm: Spaces.Size_2, // 50% width on small screens
    md: "0px",
  },
};

export const formContainer = {
  display: "flex",
  flexDirection: "column",
  gap: "42px",
  marginTop: {
    xs: "0px", // 100% width on extra-small screens
    sm: "0px", // 50% width on small screens
    md: "68px",
  },
  alignItems: {
    xs: "center", // 100% width on extra-small screens
    sm: "center", // 50% width on small screens
    md: "center",
  },
};

export const mainPaperForm = {
  width: {
    xs: "95%", // 100% width on extra-small screens
    sm: "530px", // 50% width on small screens
    md: "530px",
  },
  padding: {
    xs: Spaces.Size_2, // 100% width on extra-small screens
    sm: Spaces.Size_8, // 50% width on small screens
    md: Spaces.Size_8,
  },
  borderRadius: Spaces.Size_5,

  background: {
    xs: "transparent", // 100% width on extra-small screens
    sm: "transparent", // 50% width on small screens
    md: "linear-gradient(114deg, rgba(228, 228, 228, 0.14)7.85%, rgba(251, 251, 251, 0.06)90.19%)",
  },
  boxShadow: {
    xs: "none", // 100% width on extra-small screens
    sm: "none", // 50% width on small screens
  },
};

export const paperForm = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_9,
  padding: `${Spaces.Size_13} ${Spaces.Size_11}`,
  borderRadius: {
    xs: Spaces.Size_5, // 100% width on extra-small screens
  }
};

export const loginTitleBox = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_6,
};

export const loginInputsBox = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_4,
};

export const disclaimerContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  alignSelf: "stretch",
  justifyContent: "space-between",
  gap: Spaces.Size_2,
};

export const checkBox = {
  width: Spaces.Size_5,
  height: Spaces.Size_5,
  border: "1px solid black",
};

export const formBox = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_4,
};

export const loginButtonContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

export const emptyFieldLabel = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: Spaces.Size_1,
  fontFamily: TextVariables.Caption_1,
  color: Colors.Error_700,
};
