import { Box, Typography } from "@mui/material";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import InfoChipRisk from "./InfoChipRisk.js"

export function RiskDetailBox({ title, detail, logo }) {
  const { isXs, isMd } = useBreakpoint();

  const titleStyle = {
    fontFamily: isMd ? TextVariables.Caption_2 : TextVariables.Overline
  }
  
  return (
    <Box
      sx={{
        display: "flex",
        padding: Spaces.Size_3,
        justifyContent: "space-between",
        alignItems: "center",
        flex: "1 0 0",
        borderRadius: Spaces.Size_3,
        border: "1px solid rgba(255, 18, 246, 0.06)",
        background: "#fff"
      }}
    >
      <Box>
        <Typography sx={ titleStyle }>
          {title}
        </Typography>
        <InfoChipRisk text={detail} />
      </Box>
      <span
        className="material-symbols-outlined"
        style={{ fontSize: Spaces.Size_5, lineHeight: 1 }}
      >
        {logo}
      </span>
    </Box>
  );
}

export default RiskDetailBox;
