export function SetUserInformation(response){
    localStorage.setItem('userInformation', JSON.stringify(response.data.data));
}

export function SetSignalVisibility(status){
    localStorage.setItem('visibility', status);
}

export function GetSignalVisibility(){
    const checked = localStorage.getItem('visibility') || false;
    return checked;
}

export function SetFollowingSettings(response){
    localStorage.setItem('followingSettings', JSON.stringify(response.data.data));
}

export function ClearFollowingSettings() {
    localStorage.removeItem('followingSettings');
}
export function GetFollowingSettings(){
    const response = {
        dataExits:false,
        data:{}
    }
    const followingInfo = JSON.parse(localStorage.getItem('followingSettings')) || undefined;
    if(followingInfo !== undefined){
        response.dataExits = true;
        response.data = followingInfo;
    }
    else{
        response.dataExits = false;
    }
    return response;
}

export function GetSymbols(){
    const response = {
        dataExits:false,
        data:{}
    }
    const userInfo = JSON.parse(localStorage.getItem('symbolsData')) || {};
    if(userInfo !== undefined){
        response.dataExits = true;
        response.data = userInfo;
    }
    else{
        response.dataExits = false;
    }
    return response;
}
export function ClearSymbols() {
    localStorage.removeItem('symbolsData');
    localStorage.removeItem('selectedAssets');
    localStorage.removeItem('selectedCurrencies');
}

export function SetSymbols(response){
    localStorage.setItem('symbolsData', JSON.stringify(response.data.data));
}

export function GetUserInformation(){
    const response = {
        dataExits:false,
        data:{}
    }
    const userInfo = JSON.parse(localStorage.getItem('userInformation')) || {};
    if(userInfo !== undefined){
        response.dataExits = true;
        response.data = userInfo;
    }
    else{
        response.dataExits = false;
    }
    return response;
}