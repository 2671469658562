import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import NotificationChildrenModal from "../Notifications/NotificationChildrenModal.js";
import TitledInput from "../Inputs/TitledInput.js";
import TitledSelectorGeneral from "../Inputs/TitledSelectorGeneral.js";
import * as masterData from "../../../Master Data/masterData.js";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import { useDynamicStyles } from "../../Follower/DiscoverScreen/Styles.js";
import { Modal, Box, Button, Typography } from "@mui/material";
import MultiSelector from "../Selectors/MultiSelector.js";
import Button3px from "../Buttons/3pxButton.js";

export function AssetSymbolFilter({
  open = false,
  onApply,
  onClose,
  handleAssetChange,
  handleCurrencyChange,
  selectedAssets,
  filteredCurrencies,
  selectedCurrencies,
}) {
  const Styles = useDynamicStyles();
  const { isXs, isMd } = useBreakpoint();

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: Spaces.Size_5,
          alignItems: "center",
          gap: Spaces.Size_6,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMd ? "507px" : "92vw",
          maxWidth: "407px",
          maxHeight: "90vh",
          borderRadius: Spaces.Size_4,
          background: "white",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
            Filters
          </Typography>
          <Button
            onClick={onClose}
            sx={{
              display: "inline-block",
              position: "absolute",
              top: "23px",
              right: "20px",
              padding: "0px",
              minWidth: "auto",
            }}
          >
            <span
              style={{ color: Colors.Secondary_900, fontSize: Spaces.Size_5 }}
              className="material-symbols-outlined"
            >
              close
            </span>
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: Spaces.Size_3,
          }}
        >
          <MultiSelector
            title={"Asset"}
            options={["Forex", "CFDs", "Cryptocurrencies", "Metals"]}
            selectedValues={selectedAssets}
            handleChange={handleAssetChange}
            width="100%"
          />
          <MultiSelector
            title={"Currency"}
            options={filteredCurrencies}
            selectedValues={selectedCurrencies}
            handleChange={handleCurrencyChange}
            width="100%"
          />
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", width: "100%", gap: Spaces.Size_5}}>
          <Button3px
            HandleOnClick={onApply}
            width="100%"
            label={"Clear"}
            color={Colors.Secondary_900}
            padding={Spaces.Size_4}
            border={`1px solid ${Colors.Secondary_900}`}
          ></Button3px>
          <Button3px
            HandleOnClick={onApply}
            width="100%"
            label={"Apply"}
            color={"white"}
            backgroundColor={Colors.Error_500}
            padding={Spaces.Size_4}
          ></Button3px>
        </Box>
      </Box>
    </Modal>
  );
}

export default AssetSymbolFilter;
