import { Box, Typography, Checkbox } from "@mui/material";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";

function CustomLegend({ name, onCheck, checked, value = "", lineColor = "red" }) {
  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "flex-start",
        gap: Spaces.Size_6,
        width: "100%",

      }}
    >
      <Box
        onClick={() => onCheck}
        key={name}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          padding: "10px 16px",
          background: Colors.Secondary_50,
          borderRadius: Spaces.Size_2,
          gap: "10px",
          width: "100%",
        }}
      >
        <Checkbox
          checked={checked}
          onChange={onCheck}
          inputProps={{ "aria-label": name }}
          sx={{
            height: "14px",
            width: "14px",
            "&.Mui-checked": {
              color: Colors.Success_800,
            },
          }}
        />
        <span className="legend-label">{name}</span>
        <Typography
          className="legend-marker"
          style={{
            display: "inline-block",
            width: Spaces.Size_4,
            height: Spaces.Size_1,
            backgroundColor: lineColor,
            textAlign: "center",
            borderRadius: Spaces.Size_1,
          }}
        ></Typography>
      </Box>
    </Box>
  );
}

export default CustomLegend;
