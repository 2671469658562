//Follower Main component render when the user accepts disclouser adn the account type is a follower
import FollowProfile from "./ProfileScreen/MyProfile.js";
import DiscoverScreen from "./DiscoverScreen/DiscoverScreen.js";
import FollowOrders from "./OrdersScreen/orders.js";
import DiscoverSignal from "./DiscoverScreen/DiscoverSignal.js";
import "../../css/SignalProvider.scss";
import IdleTimerContainer from "../../config/iddleTimerContainer";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { baseEnv } from "../../App";
import { Box } from "@mui/material";

function Follower({page, children}) {
  //-----------------------------------------------------------
  //Declaration of constants, variables and hooks
  //-----------------------------------------------------------

  const cookies = new Cookies();
  const user = cookies.get("user");
  const userType = cookies.get("account type"); //Gets from cookies is the user is follower type

  //-----------------------------------------------------------
  //Functions and validations area
  //-----------------------------------------------------------

  //This validation protects the view in case someone access the route without do the login correctly
  if (user == null || userType !== "follower") {
    return <Navigate to={baseEnv + "/"} />;
  }

  return (
    <Box >
      <IdleTimerContainer></IdleTimerContainer>
      {page === "followingSettings" && <FollowProfile></FollowProfile>}
      {page === "discover" && <DiscoverScreen/>}
      {page === "orders" && <FollowOrders/>}
      {page === "discoverMore" && <DiscoverSignal/>}
    </Box>

  );
}

export default Follower;
