import { Box, Typography } from "@mui/material";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";

export function InfoChipRisk({ text }) {
  let value = 0;
  let colorRisk = Colors.Secondary_700;
  let backgroundColorRisk = Colors.Secondary_50;
  if(text !== undefined){
    value = parseInt(text);
  }

  if(value <= 5){
    colorRisk = Colors.Success_500;
    backgroundColorRisk = Colors.Success_50
  }
  else if(value > 5 && value <= 7){
    colorRisk = Colors.Warning_500;
    backgroundColorRisk = Colors.Warning_50
  }
  else{
    colorRisk = Colors.Error_500;
    backgroundColorRisk = Colors.Error_50
  }

  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "1px",
        padding: `${"3px"} ${Spaces.Size_2}`,
        alignItems: "center",
        gap: "2px",
        borderRadius: Spaces.Size_2,
        background: backgroundColorRisk,
      }}
    >
      <Typography sx={{ fontFamily: TextVariables.Caption_1, color: colorRisk, lineHeight: 1 }}>{value}</Typography>
    </Box>
  );
}

export default InfoChipRisk;
