import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

const CookieContext = createContext(null);

export const useCookies = () => {
    return useContext(CookieContext);
};

const CookieChecker = ({ children }) => {
    const [user, setUser] = useState(null);
    const [type, setType] = useState(null);
    const [token, setToken] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const cookies = new Cookies();
        const userCookie = cookies.get("user");
        const typeCookie = cookies.get("account type");
        const tokenCookie = cookies.get("token");

        if (userCookie == null || typeCookie == null || tokenCookie == null) {
            navigate("/login");
        }

        setUser(userCookie);
        setType(typeCookie);
        setToken(tokenCookie);
    }, [navigate]);

    return (
        <CookieContext.Provider value={{ user, type, token }}>
            {children}
        </CookieContext.Provider>
    );
};

export default CookieChecker;
