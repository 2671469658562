import { Button, Typography } from "@mui/material";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";

function Button3px({ HandleOnClick, label, color="black", backgroundColor, border, padding = Spaces.Size_3, width = "auto"  }) {
  return (
    <Button
      onClick={HandleOnClick}
      sx={{
        backgroundColor: backgroundColor,
        color: color,
        textTransform: "none",
        padding: padding,
        gap: Spaces.Size_2,
        justifyContent: "center",
        alignItems: "center",
        width: width,
        border: border,
        borderRadius: Spaces.Size_2,
        "&:hover": {
          backgroundColor: backgroundColor, // Cambia el color al hacer hover
          opacity: 0.8, // Aplica opacidad al hacer hover
        },
      }}
    >
      <Typography sx={{fontFamily: TextVariables.Caption_1_Bold, lineHeight: 1}}>{label}</Typography>
    </Button>
  );
}

export default Button3px;
