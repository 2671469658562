import { Box } from "@mui/material";

function IconComponent(props) {

    return(
        <Box
            component="span"
            className="material-symbols-outlined"
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: props.size,
            }}
        >
            {props.icon}
        </Box>
    );
}

export default IconComponent;