export const Colors = {
    Primary_50: "rgba(255, 229, 229, 1)",
    Primary_100: "rgba(255, 178, 178, 1)",
    Primary_200: "rgba(255, 153, 153, 1)",
    Primary_300: "rgba(255, 102, 102, 1)",
    Primary_400: "rgba(255, 51, 51, 1)",
    Primary_500: "rgba(255, 0, 0, 1)",
    Primary_600: "rgba(239, 0, 0, 1)",
    Primary_700: "rgba(235, 0, 0, 1)",
    Primary_800: "rgba(188, 0, 0, 1)",
    Primary_900: "rgba(141, 0, 0, 1)",

    Secondary_50: "rgba(236, 241, 247, 1)",
    Secondary_100: "rgba(198, 208, 217, 1)",
    Secondary_200: "rgba(164, 176, 189, 1)",
    Secondary_300: "rgba(129, 145, 162, 1)",
    Secondary_400: "rgba(104, 123, 142, 1)",
    Secondary_500: "rgba(79, 101, 123, 1)",
    Secondary_600: "rgba(67, 88, 107, 1)",
    Secondary_700: "rgba(52, 69, 85, 1)",
    Secondary_800: "rgba(38, 51, 64, 1)",
    Secondary_900: "rgba(21, 32, 42, 1)",

    Info_50: "rgba(245, 248, 255, 1)",
    Info_100: "rgba(239, 244, 255, 1)",
    Info_200: "rgba(209, 224, 255, 1)",
    Info_300: "rgba(178, 204, 255, 1)",
    Info_400: "rgba(132, 173, 255, 1)",
    Info_500: "rgba(82, 139, 255, 1)",
    Info_600: "rgba(41, 112, 255, 1)",
    Info_700: "rgba(21, 94, 239, 1)",
    Info_800: "rgba(0, 78, 235, 1)",
    Info_900: "rgba(0, 34, 102, 1)",

    Success_50: "rgba(230, 245, 234, 1)",
    Success_100: "rgba(196, 229, 202, 1)",
    Success_200: "rgba(158, 213, 169, 1)",
    Success_300: "rgba(119, 197, 136, 1)",
    Success_400: "rgba(88, 185, 111, 1)",
    Success_500: "rgba(56, 173, 86, 1)",
    Success_600: "rgba(48, 158, 76, 1)",
    Success_700: "rgba(37, 140, 65, 1)",
    Success_800: "rgba(28, 123, 62, 1)",
    Success_900: "rgba(7, 92, 36, 1)",

    Warning_50: "rgba(255, 255, 255, 1)",
    Warning_100: "rgba(254, 239, 199, 1)",
    Warning_200: "rgba(254, 223, 137, 1)",
    Warning_300: "rgba(254, 200, 75, 1)",
    Warning_400: "rgba(253, 176, 34, 1)",
    Warning_500: "rgba(247, 144, 9, 1)",
    Warning_600: "rgba(220, 104, 3, 1)",
    Warning_700: "rgba(220, 104, 3, 1)",
    Warning_800: "rgba(220, 104, 3, 1)",
    Warning_900: "rgba(220, 104, 3, 1)",

    Error_50: "rgba(255, 229, 229, 1)",
    Error_100: "rgba(255, 178, 178, 1)",
    Error_200: "rgba(255, 153, 153, 1)",
    Error_300: "rgba(255, 102, 102, 1)",
    Error_400: "rgba(255, 51, 51, 1)",
    Error_500: "rgba(255, 0, 0, 1)",
    Error_600: "rgba(239, 0, 0, 1)",
    Error_700: "rgba(235, 0, 0, 1)",
    Error_800: "rgba(188, 0, 0, 1)",
    Error_900: "rgba(141, 0, 0, 1)",

    Gray_50: "rgba(252, 252, 252, 1)",
    Gray_100: "rgba(245, 245, 245, 1)",
    Gray_200: "rgba(229, 229, 229, 1)",
    Gray_300: "rgba(214, 214, 214, 1)",
    Gray_400: "rgba(163, 163, 163, 1)",
    Gray_500: "rgba(115, 115, 115, 1)",
    Gray_600: "rgba(82, 82, 82, 1)",
    Gray_700: "rgba(66, 66, 66, 1)",
    Gray_800: "rgba(41, 41, 41, 1)",
    Gray_900: "rgba(20, 20, 20, 1)",
}

export default Colors;