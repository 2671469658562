import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Typography, Paper, Box, Checkbox, Button,IconButton } from "@mui/material";
import * as styles from "../Login/Styles.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import logo_communitraders from "../../assets/logos/Logo-communitraders.svg";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import TitledInput from "../../components/Generals/Inputs/TitledInput.js";
import TitledPasswordInput from "../../components/Generals/Inputs/TitledPasswordInput.js";
import TitledSelector from "../Generals/Selectors/TitledSelector.js";
import ApplyButton from "../Generals/Buttons/ApplyButton.js";
import * as MasterData from "../../Master Data/masterData.js";
import LoadingSpinner from "../../components/Generals/Loaders/SpinLoader.js";
import userService from "../../services/user";
import loginService from "../../services/login";
import { useTranslation } from "react-i18next";
import NotificationModal from "../Generals/Notifications/NotificationModal.js";
import * as Encryptor from "../../Tools/AESEncrypter";
import { ClearSymbols } from "../../Tools/LocalStorage.js";

function LoginView(props) {

  //#region "Instantiation of States"
  const [t] = useTranslation("global");

  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState("");
  const [showUrl, setShowUrl] = useState(false);
  const [errors, setErrors] = useState({
    usu: false,
    pass: false,
    role: false,
    platform: false,
    server: false,
    checked: false,
  });
  const [formValues, setFormValues] = useState({
    usu: "",
    pass: "",
    role: "",
    platform: "",
    server: "",
    checked: false,
  });
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });
  const [signalLogin, setSignalLogin] = useState(0);

//#endregion

  //#region "Cookies"
  const navigate = useNavigate();
  const cookies = new Cookies();

  //-----------------------------------------------------------------------
  // Credentials and token cookies deleted
  //-----------------------------------------------------------------------
  const env = process.env.REACT_APP_URL;
  //#endregion 
  
  //#region "Handlers"
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: event.target.value,
    }));
  };

  const handleSubmit = () => {
    let valid = true;
    let newErrors = {};

    // Verificar cada campo
    for (const [key, value] of Object.entries(formValues)) {
      if (value === "" || value === false) {
        newErrors[key] = true;
        valid = false;
      } else {
        newErrors[key] = false;
      }
    }

    setErrors(newErrors);

    if (valid) {
      // Procesar el formulario
      handleLogin();
    } else {
      setModalProps({
        open: true,
        iconType: "error",
        title: t("login.Please, fill in the missing data"),
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };

  const handleUserInput = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (inputValue.length <= 10) {
      setFormValues({
        ...formValues,
        usu: inputValue,
      });
    }
  };

  const handlePasswordInput = (event) => {
    if (event.target.value.length <= 30) {
      setFormValues({
        ...formValues,
        pass: event.target.value,
      });
    }
  };

  const handleRoleSelector = (event) => {
    setFormValues({
      ...formValues,
      role: event.target.value,
    });
  };

  const handlePlatformSelector = (event) => {
    setFormValues({
      ...formValues,
      platform: event.target.value,
    });
  };

  const handleServerSelector = (event) => {
    setFormValues({
      ...formValues,
      server: event.target.value,
    });
    cookies.set("url", event.target.value, { path: "/" });
    const urlUpdatedEvent = new CustomEvent("updatedUrl", {
      detail: { value: event.target.value },
    });

    window.dispatchEvent(urlUpdatedEvent);
  };

  const handleDisclouserCheck = (event) => {
    setFormValues({
      ...formValues,
      checked: event.target.checked,
    });
  };

  const handleTradeViewIcon = () => {
    window.location.href = "https://www.tvmarkets.com/en";
};

  const handleLogin = (e) => {
    //Calls login service to do login into the communitraders api
    setLoading(true);
    loginService
      .login({
        //Login request body
        platform: formValues.platform,
        environment: formValues.server,
        role: formValues.role,
        account: formValues.usu,
        password: formValues.pass,
        ip: ip,
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        //Checking out if the response return the login data
        if (response.data != null) {
          var user = response.data;
          cookies.set("token", user.token, { path: "/" });
          const tokenUpdatedEvent = new CustomEvent("tokenUpdatedEvent", {
            detail: { value: user.token },
          });

          window.dispatchEvent(tokenUpdatedEvent);

          //If the response was correct, the credentials hooks are deleted
          let loginMessage = "";

          // Checks out if the account type is signal or follower
          if (formValues.role === "signal") {
            //If the account type is signal, set the cookies with the access data (user, account type and token)
            cookies.set("user", formValues.usu, { path: "/" });
            cookies.set("account type", formValues.role, { path: "/" });
            navigate("/SignalProvider/Profile");
            //If the account type is signal the disclouser screen will be displayed
            loginMessage = "login.Welcome signal provider user";
          } else if (formValues.role === "follower") {
            //If the account type is follower, set the cookies with the access data (user, account type and token)
            cookies.set("user", formValues.usu, { path: "/" });
            cookies.set("account type", formValues.role, { path: "/" });

            const sharedSignal = localStorage.getItem("shared_signal_link");
            console.log("link compartido:"+sharedSignal);
            if(sharedSignal && sharedSignal.length > 0){
              navigate("/shared_signal_link/"+sharedSignal);
              return;
            }

            if (signalLogin !== 0) {
              navigate(`/Follower/Discover/Strategy/${signalLogin}`);
            } else {
              navigate("/Follower/Discover");
            }
          }
          //Show an alert message and redirect to disclouser screen

          //if the response is null a warning will be displayed
        } else {
          setModalProps({
            open: true,
            iconType: "error",
            title: t("login.Invalid access credentials"),
            onAcceptLabel: "Continue",
            onAccept: () => handleCloseNotification(),
          });
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);

        if (
          error.response.data.message ===
          "The account does not belong to the selected role. please try again with another available role"
        ) {
          setModalProps({
            open: true,
            iconType: "error",
            title: "Wrong credentials",
            message:
              "The account does not belong to the selected role. please try again with another available role",
            onAcceptLabel: "Continue",
            onAccept: () => handleCloseNotification(),
          });
        } else if (
          error.response.data.message ===
          "The account does not exist on the selected server."
        ) {
          setModalProps({
            open: true,
            iconType: "error",
            title: "Wrong credentials",
            message: "The account does not exist on the selected server.",
            onAcceptLabel: "Continue",
            onAccept: () => handleCloseNotification(),
          });
        } else if (
          error.response.data.message === "The password entered is incorrect"
        ) {
          setModalProps({
            open: true,
            iconType: "error",
            title: "Wrong credentials",
            message: "Please check your credentials and try again.",
            onAcceptLabel: "Continue",
            onAccept: () => handleCloseNotification(),
          });
        } else {
          setModalProps({
            open: true,
            iconType: "error",
            title: "Server error",
            message:
              "An unexpected error has occurred, please contact an administrator",
            onAcceptLabel: "Continue",
            onAccept: () => handleCloseNotification(),
          });
        }
        setLoading(false);
      });
  };

  //#endregion
  
  //#region "Requests"
  const getIp = () => {
    setLoading(true); //Starts loading spinning
    userService
      .getIpClient //Calls the method getIpClient from user service (axios request)
      ()
      .then((response) => {
        //Waits for a response from the axios request
        setLoading(false); //Stops loading spinning
        return response;
      })
      .then((response) => {
        if (response !== null) {
          setIp(response.data); //If response is correct, sets ip into the hook ip.
        } else {
          getIp();
        }
      })
      .catch((error) => {
        setLoading(false); //Stops loading spinning
        console.log(error);
        getIp();
      });
  };
//#endregion

  //#region "useEffects"
  //USe Effect hook triggered once per render, it calls a function to request the user ip
  useEffect(() => {
    getIp();
    const path = window.location.pathname;
    const signalLogin = path.split("/").pop();
    if (
      signalLogin !== undefined &&
      signalLogin.length > 0 &&
      signalLogin !== "login"
    ) {
      setSignalLogin(signalLogin);
      const user = cookies.get("user");
      const userType = cookies.get("account type"); //Gets from cookies is the user is Signal provider type
      const token = cookies.get("token"); //Gets from cookies is the user is Signal provider type
      if (
        token !== undefined &&
        token.length >= 7 &&
        user !== undefined &&
        user.length >= 0 &&
        userType !== undefined
      ) {
        if(userType === "follower"){
          navigate(`/Follower/Discover/Strategy/${signalLogin}`);
        }
        else{
          navigate(`/SignalProvider/Profile`);
        }
        
      }
    } else {
      cookies.remove("user", { path: "/" });
      cookies.remove("account type", { path: "/" });
      cookies.remove("token", { path: "/" });
    }
    ClearSymbols();
  }, []);

//#endregion
  

  //#region "HTML"
  return (
    <Box sx={styles.mainContainer}>
      {loading ? <LoadingSpinner /> : <></>}
      <NotificationModal
        open={modalProps.open}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onAccept={modalProps.onAccept}
      />
      <Box sx={styles.logoContainer}>
        <img src={logo_communitraders} alt="Tradeview" width={"233"} />
        <Typography sx={styles.logoTitle}>
          {t("login.followExperiencedTraders")}
        </Typography>
      </Box>
      <Box sx={styles.formContainer}>
        <Paper elevation={1} sx={styles.mainPaperForm}>
          <Paper elevation={1} sx={styles.paperForm}>
            <Box sx={styles.loginTitleBox}>
              <Typography
                sx={{ fontFamily: TextVariables.Body_2_Bold, lineHeight: "1" }}
              >
                {t("login.loginToYourAccount")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: TextVariables.Body_4,
                  color: Colors.Secondary_600,
                }}
              >
                {t("login.accessCommunitraders")}{"  "}
                <Button
                  component="a"
                  href="/SignalRegister"
                  sx={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    padding: 0,
                    margin: 0,
                    minWidth: "auto",
                    textTransform: "none",
                    background: "none",
                    fontFamily: TextVariables.Body_4,
                    verticalAlign: "0",
                    "&:hover": {
                      background: "none",
                      textDecoration: "underline",
                    },
                  }}
                >
                  {t("login.Here")}
                </Button>
                .
              </Typography>
            </Box>
            <Box sx={styles.loginInputsBox}>
              <TitledInput
                name="login"
                value={formValues.usu}
                title={"MetaTrader account number"}
                placeholder={t("login.enterYourLogin")}
                handleChange={handleUserInput}
                error={errors.usu}
                helperText={
                  errors.usu ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        {t("login.pleaseEnterUser")}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              ></TitledInput>
              <TitledPasswordInput
                name="password"
                title={t("login.Password")}
                value={formValues.pass}
                type="password"
                placeholder={t("login.enterYourPassword")}
                handleChange={handlePasswordInput}
                error={errors.pass}
                helperText={
                  errors.pass ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        {t("login.pleaseEnterPassword")}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              ></TitledPasswordInput>
              <TitledSelector
                title={t("login.role")}
                name="role"
                value={formValues.role}
                handleChange={handleRoleSelector}
                options={MasterData.roleOptions}
                error={errors.role}
                placeholder={"Select a role"}
                helperText={
                  errors.role ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        {t("login.pleaseSelectRole")}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              />
              <TitledSelector
                title={t("login.platform")}
                name="platform"
                value={formValues.platform}
                handleChange={handlePlatformSelector}
                options={MasterData.platformOptions}
                placeholder={"Select a platform"}
                error={errors.platform}
                helperText={
                  errors.platform ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        {t("login.pleaseSelectPlatform")}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              />
              <TitledSelector
                title={t("login.server")}
                name="server"
                value={formValues.server}
                handleChange={handleServerSelector}
                options={
                  formValues.platform === "metatrader4"
                    ? MasterData.serverOptionsMt4
                    : MasterData.serverOptionsMt5
                }
                placeholder={"Select a server"}
                error={errors.server}
                helperText={
                  errors.server ? (
                    <Box sx={styles.emptyFieldLabel}>
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: Spaces.Size_3, color: "red" }}
                      >
                        error
                      </span>
                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_1,
                          color: Colors.Error_700,
                        }}
                      >
                        {t("login.pleaseSelectServer")}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
              />
            </Box>
            <Box sx={styles.disclaimerContainer}>
              <Checkbox
                checked={formValues.checked}
                onChange={handleDisclouserCheck}
                color="primary" // Cambia el color del checkbox si lo deseas
                sx={{ padding: "0px" }}
              />
              <Box>
                <Typography sx={{ fontFamily: TextVariables.Overline }}>
                  {" "}
                  {t("login.disclaimerText")}
                </Typography>
                {errors.checked && (
                  <Typography sx={styles.emptyFieldLabel}>
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: Spaces.Size_3, color: "red" }}
                    >
                      error
                    </span>
                      {t("login.pleaseCheckBoxToContinue")}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={styles.loginButtonContainer}>
              <ApplyButton HandleOnClick={handleSubmit}>{t("login.continue")}</ApplyButton>
            </Box>
          </Paper>
        </Paper>
      </Box>
    </Box>
  );
  //#endregion
}

export default LoginView;
