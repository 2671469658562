//Follower settings component render when the user click the setting button in sidebarfollower component
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../css/signal/signalProfile.scss";
import userService from "../../../services/user";
import signalService from "../../../services/signalProvider";

import profileImage from "../../../assets/SignalTestImage/profileImage.png";
import { InfoDetailBox } from "../../Generals/Cards//infoDetailBox.js";
import { InfoDetailLogoBox } from "../../Generals/Cards/InfoDetailLogoBox.js";
import LoadingSpinner from "../../Generals/Loaders/SpinLoader";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import * as masterData from "../../../Master Data/masterData.js";
import { Spaces, mainBox } from "../../../assets/Variables/SpaceVariables.js";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Chip,
  Paper,
  Grid,
} from "@mui/material";
import {
  SetSignalVisibility,
  SetUserInformation,
} from "../../../Tools/LocalStorage";
import TableComponent from "../../Generals/Tables/TableComponent.js";
import TableToolTip from "../../Generals/Tooltips/TableToolTip.js";
import InfoChip from "../../Generals/Cards/InfoChip";
import IconComponent from "../../Generals/Icons/IconComponent.js";
import Cookies from "universal-cookie";
import ShareLinkModal from "../../Generals/Notifications/ShareLinkModal";
import SocialHub from "./SocialHub";
import StatisticalModule from "./StatisticalModule";
import FloatingNotificacion from "../../Generals/Notifications/FloatingNotification";
import NotificationModal from "../../Generals/Notifications/NotificationModal";
import { useDynamicStyles } from "./Styles";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import StatusModule from "../../Generals/Cards/StatusModule";
import * as Encryptor from "../../../Tools/AESEncrypter";

function SignalProfile(props) {
  //#region "Instantiation of States"
  const { isXs, isMd } = useBreakpoint();
  const styles = useDynamicStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openUnableModal, setOpenUnableModal] = useState(false);
  const [strategyImage, setStrategyImage] = useState(profileImage);
  const [totalAmount, SetTotalAmount] = useState(0);
  const [page, setPage] = useState(1); // Estado para la página actual
  const [pageCount, setPageCount] = useState(0); // Estado para el número de páginas
  const [rows, setRows] = useState([]); // Estado para los datos de las filas
  const [loading, setLoading] = useState(false);
  const [strategyInformation, setStrategyInformation] = useState({
    best_instrument_1: {
      name: "",
    },
    best_instrument_2: {
      name: "",
    },
    best_instrument_3: {
      name: "",
    },
  });
  const [account, SetUserAccount] = useState ([])
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onAccept: undefined,
  });
  const [accountEncripted, SetAccountEncripted] = useState (0)

  const cookies = new Cookies();
  const url = cookies.get("url");

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);
  //#endregion

  //#region "Declaration of Normal Variables or Constants"

  const columns = [
    {
      field: "time",
      headerClassName: "header_table",
      renderHeader: () => <>{"Time"}</>,
    },
    {
      field: "strategy_name",
      headerClassName: "header_table",
      renderHeader: () => <>{"Strategy Name"}</>,
    },
    {
      field: "strategy_type",
      headerClassName: "header_table",
      renderHeader: () => <>{"Strategy type"}</>,
      renderCell: (params) => {
        if (params.row.strategy_type.toLowerCase().includes("human")) {
          return (
            <Chip label={params.row.strategy_type} sx={styles.ChipHumanStyle} />
          );
        } else if (
          params.row.strategy_type.toLowerCase().includes("supervised robot")
        ) {
          return (
            <Chip
              label={params.row.strategy_type}
              sx={styles.ChipSupervisedStyle}
            />
          );
        } else {
          return (
            <Chip label={params.row.strategy_type} sx={styles.ChipRobotStyle} />
          );
        }
      },
    },
    {
      field: "fee_value",
      headerClassName: "header_table",
      renderHeader: () => <>{"Fee"}</>,
    },
    {
      field: "drawdown_mode",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Type of drawdown protection">
          <Box sx={styles.tooltipBox}>
            {"DD Protection "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "max_drowdown",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="The max value of drawdown to reach the limit">
          <Box sx={styles.tooltipBox}>
            {"Max. DD "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "disable_account_on_drawdown",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Defines whether the account will be disabled if the maximum drawdown value is reached">
          <Box sx={styles.tooltipBox}>
            {"Disable ON DD "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "visibility",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Defines whether the strategy is visible to followers">
          <Box sx={styles.tooltipBox}>
            {"Visibility"}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "ip",
      headerClassName: "header_table",
      renderHeader: () => <>{"IP Address"}</>,
    },
    {
      field: "message",
      headerClassName: "header_table",
      renderHeader: () => <>{"Message"}</>,
    },
  ];
  //#endregion

  //#region "Handlers"
  const handleChangePage = (page) => {
    setPage(page);
    RequestSettingsLogs(10, page);
  };

  const handleEditStrategy = () => {
    navigate("/SignalProvider/StrategySettings");
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(window.origin + "/shared_signal_link/" + accountEncripted);
  };

  const handleCloseUnableAccount = () => {
    RequestEnableAccount();
  };
  //#endregion

  //#region "Requests"
  const RequestEnableAccount = async () => {
    try {
      setLoading(true);
      const response = await signalService.reactivateAccount();
      if (response.data != null) {
        setLoading(false);
        setOpenUnableModal(false);
        setModalProps({
          open: true,
          iconType: "success",
          title: "Signal account re-enable",
          message:
            "Your account has been successfully re-enabled and will now execute trades according to your settings. You can disable the automatic disabling feature in your profile settings.",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(true),
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Error re-enable signal account",
        message:
          "An error occurred while re-enabling the account, please try again, if the problem persists contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(false),
      });
    }
    setLoading(false);
  };

  const RequestDashboardData = async () => {
    try {
      setLoading(true);
      const response = await userService.getInformation();
      if (response.data != null) {
        setLoading(false);
        setStrategyInformation(response.data.data);
        SetUserInformation(response);
        SetUserAccount(response.data.data.account);
        SetSignalVisibility(response.data.data.visibility);
        if (response.data.data.account === response.data.data.strategy_name) {
          navigate("/SignalProvider/StrategySettings");
        }
        if (response.data.data.image.includes("/")) {
          setStrategyImage(`${url}/${response.data.data.image}`);
        }
        if (response.data.data.read_only_was_triggered) {
          setOpenUnableModal(true);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message:
          "An error has occurred while loading the information. Please try reloading, if the problem persists please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };

  const RequestSettingsLogs = async (quantity, page) => {
    try {
      setLoading(true);
      const response = await signalService.masterHistoricalSettings(
        quantity,
        page
      );
      if (response.data.data.data) {
        setLoading(false);
        let actualRows = response.data.data.data.map((log, index) => {
          let row = {
            id: index,
            disable_account_on_drawdown: log.disable_account_on_drawdown,
            drawdown_mode: log.drawdown_mode,
            fee_value: log.fee_value,
            max_drowdown: log.max_drowdown,
            message: log.message,
            strategy_name: log.strategy_name,
            strategy_type: log.strategy_type,
            time: log.time,
            visibility: log.visibility,
            ip: log.ip,
          };
          return row;
        });
        setRows(actualRows);
        if(response.data.data.total_pages){
          setPageCount(response.data.data.total_pages);
          SetTotalAmount(response.data.data.total_registers);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message:
          "An error has occurred while loading the information. Please try reloading, if the problem persists please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };
  //#endregion

  //#region "useEffects"
  useEffect(() => {
    RequestDashboardData();
    RequestSettingsLogs(10, page);
  }, []);
  useEffect(() => {
    if(account && account>0)
      SetAccountEncripted(Encryptor.encrypt(account))
  }, [account]);
  //#endregion

  //#region "HTML"
  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <NotificationModal
        open={modalProps.open}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onAccept={modalProps.onAccept}
      />
      <FloatingNotificacion
        open={openUnableModal}
        handleOnClick={handleCloseUnableAccount}
      ></FloatingNotificacion>
      <Box sx={styles.form}>
        {isXs && <StatusModule></StatusModule>}
        <Box sx={styles.profileSocialContainer}>
          <Box sx={styles.profileContainer}>
            <Box sx={styles.profileTitleBox}>
              <Box sx={styles.avatarTitleBox}>
                <Box>
                  <Avatar
                    alt="strategy image"
                    src={strategyImage}
                    sx={{ width: 95, height: 95 }}
                  ></Avatar>
                </Box>
                <Box sx={styles.descriptionBox}>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_3_Bold,
                    }}
                  >
                    {strategyInformation.strategy_name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Caption_1,
                    }}
                  >
                    {strategyInformation.description}
                  </Typography>
                  <Button onClick={handleOpen} sx={styles.shareLinkButton}>
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: Spaces.Size_3, lineHeight: 1 }}
                    >
                      share
                    </span>
                    <Typography
                      sx={{
                        fontFamily: TextVariables.Caption_1_Bold,
                        lineHeight: 1,
                      }}
                    >
                      Share link
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <ShareLinkModal
                open={open}
                onClose={handleClose}
                handleCopy={() => navigator.clipboard.writeText(window.origin + "/shared_signal_link/" + accountEncripted)}
                title="Share this signal provider's profile"
                sharedLink={window.origin + "/shared_signal_link/" + accountEncripted}
              />
              <Box>
                <Button
                  onClick={handleEditStrategy}
                  sx={{
                    display: "inline-block",
                    color: "black",
                    padding: "0px",
                    minWidth: "auto",
                  }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_6, lineHeight: "1" }}
                  >
                    edit
                  </span>
                </Button>
              </Box>
            </Box>
            <Box sx={styles.strategyOverviewBox}>
              <Box sx={styles.StrategyOverviewTitleBox}>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Body_4_Bold,
                    lineHeight: 1,
                  }}
                >
                  Strategy overview
                </Typography>
                <Box sx={styles.chipsContainer}>
                  <InfoChip
                    text={`Currency: ${strategyInformation.currency}`}
                  />
                  <InfoChip
                    text={`Strategy type: ${strategyInformation.strategy_type}`}
                  />
                </Box>
              </Box>
              <Grid container spacing={2} sx={{height: "auto"}}>
                <Grid item xs={6} sm={3} md={3}>
                  <InfoDetailBox
                    title={"Leverage"}
                    detail={strategyInformation.leverage}
                    logo={"page_info"}
                  ></InfoDetailBox>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <InfoDetailBox
                    title={"Account type"}
                    detail={strategyInformation.account_type}
                    logo={"assignment_ind"}
                  ></InfoDetailBox>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <InfoDetailBox
                    title={"Drawdown"}
                    detail={strategyInformation.max_drawdown}
                    logo={"swap_vert"}
                  ></InfoDetailBox>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  {(strategyInformation.fee_value !== strategyInformation.fee_new_value) ?
                    <InfoDetailBox
                      title={"Fee"}
                      detail={`${strategyInformation.fee_value}/${strategyInformation.fee_new_value} %`}
                      logo={"sell"}
                    ></InfoDetailBox>
                    :
                    <InfoDetailBox
                      title={"Fee"}
                      detail={`${strategyInformation.fee_value}%`}
                      logo={"sell"}
                    ></InfoDetailBox>
                  }
                </Grid>
              </Grid>
            </Box>
            {(strategyInformation.fee_value !== strategyInformation.fee_new_value) ? 
              <Box sx={{
                display:'flex',
                justifyContent:'center',
                color:'#dc3545',
                fontWeight:'600'
              }}>
                {"Important!, this signal provider has just updated its fee from " + strategyInformation.fee_value + "% to " + strategyInformation.fee_new_value + "%. This new value will be applied once the current month's performance is paid"}
              </Box>
              : null
            }
            <Box sx={styles.strategyOverviewBox}>
              <Box sx={styles.StrategyOverviewTitleBox}>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Body_4_Bold,
                    lineHeight: 1,
                  }}
                >
                  Most traded symbols
                </Typography>
              </Box>
              <Grid container spacing={2} sx={{height: "auto"}}>
                <Grid item xs={12} sm={4} md={4}>
                  <InfoDetailLogoBox
                    title={strategyInformation.best_instrument_1.name}
                    detail={`${strategyInformation.best_instrument_1.percentage}%`}
                    asset1={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_1.name.slice(0, 3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                    asset2={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_1.name.slice(3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                  ></InfoDetailLogoBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <InfoDetailLogoBox
                    title={strategyInformation.best_instrument_2.name}
                    detail={`${strategyInformation.best_instrument_2.percentage}%`}
                    asset1={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_2.name.slice(0, 3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                    asset2={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_2.name.slice(3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                  ></InfoDetailLogoBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <InfoDetailLogoBox
                    title={strategyInformation.best_instrument_3.name}
                    detail={`${strategyInformation.best_instrument_3.percentage}%`}
                    asset1={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_3.name.slice(0, 3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                    asset2={
                      masterData.assets.find(
                        (asset) =>
                          asset.label ===
                          strategyInformation.best_instrument_3.name.slice(3)
                      )?.flag ||
                      masterData.assets.find(
                        (asset) => asset.label === "defaultFlagPath"
                      )?.flag // Valor por defecto si no encuentra el asset
                    }
                  ></InfoDetailLogoBox>
                </Grid>
              </Grid>
              <Box sx={styles.strategyCardsBox}></Box>
            </Box>
          </Box>
          <SocialHub
            mode={"signal"}
            signalInformation={strategyInformation}
            signalImage={strategyImage}
            signalAccount={account}
          ></SocialHub>
        </Box>
        <StatisticalModule
          strategyInfo={strategyInformation}
        ></StatisticalModule>
        <Paper sx={styles.settingsLogsContainer}>
          <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
            Historical Settings
          </Typography>
          <Box sx={{ width: "100%", marginTop: "30px", overflowX: 'auto'}}>
            <Box sx={{ minWidth: "1300px" }}>
              <TableComponent
                rows={rows}
                page={page}
                totalAmount={totalAmount}
                pageCount={pageCount}
                handleChangePage={handleChangePage}
                columns={columns}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
  //#endregion
}

export default SignalProfile;
