import React, { useState } from "react";
import { Modal, Box, Button, Typography, Snackbar } from "@mui/material";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import Button3px from "../Buttons/3pxButton.js";

function FloatingNotificacion({ open, handleOnClick }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      ContentProps={{
        sx: {
          backgroundColor: Colors.Error_50, // Cambia el color de fondo
          color: Colors.Secondary_700, // Cambia el color de la letra
          borderRadius: Spaces.Size_2, // Cambia el borderRadius
          padding: "0", // Espaciado interior
          border: `1px solid ${Colors.Error_400}`,
          "& .MuiSnackbarContent-message": {
            padding: "0", // Elimina el padding de la parte superior e inferior
          },
        },
      }}
      message={
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: Spaces.Size_4,
            gap: Spaces.Size_2,
            width: "414px",
            justifyContent: "flex-end",
          }}
        >
          <span
            className="material-symbols-outlined"
            style={{ fontSize: Spaces.Size_4, color: Colors.Error_400,  }}
          >
            sentiment_dissatisfied
          </span>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: Spaces.Size_4,
              justifyContent: "flex-end",
            }}
          >
            <Typography sx={{ fontFamily: TextVariables.Caption_1 }}>
              Your trade has reached the maximum drawdown limit, so new trades
              are blocked. You can re-enable your account at any time by
              clicking on:
            </Typography>
            <Button3px
              HandleOnClick={handleOnClick}
              label={"Enable account"}
              color={Colors.Secondary_700}
              border={`1px solid ${Colors.Secondary_700}`}
            ></Button3px>
          </Box>
        </Box>
      }
    />
  );
}

export default FloatingNotificacion;
