export const compressImageToJPEGOrPNG = (imageFile, quality = 0.7, format = 'image/jpeg') => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.readAsDataURL(imageFile);
  
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          // Ajustar el tamaño del canvas a la imagen original
          canvas.width = img.width;
          canvas.height = img.height;
  
          // Dibujar la imagen en el canvas
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
          // Convertir la imagen a JPEG o PNG y comprimirla
          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob); // Retornar la imagen comprimida como Blob
              } else {
                reject(new Error('Error al comprimir la imagen'));
              }
            },
            format, // 'image/jpeg' o 'image/png'
            quality // Nivel de compresión para JPEG (0.0 a 1.0)
          );
        };
  
        img.onerror = () => {
          reject(new Error('Error al cargar la imagen'));
        };
      };
  
      reader.onerror = () => {
        reject(new Error('Error al leer el archivo de imagen'));
      };
    });
  };