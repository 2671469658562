import { Line } from "react-chartjs-2";
import { Box, Typography, Checkbox } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { useDynamicStyles } from "./Styles.js";

ChartJs.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function LinesChart({ dataChart, yLabel = "No defined" }) {
  const styles = useDynamicStyles();
  const [data, setData] = useState({
    labels: ["01"],
    datasets: [
      {
        label: "Profit",
        data: [23],
        borderColor: "rgba(255, 0, 0, 0.6)",
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        yAxisID: "y-axis-volumen",
        tension: 0.4,
      },
    ],
  });

  useEffect(() => {
    if (dataChart) {
      setData(dataChart);
    }
  }, [dataChart]);

  const OptionsLine = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: false, // Oculta la leyenda predeterminada
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: false,
          text: "Meses",
        },
        grid: {
          display: false, // Oculta las líneas de la cuadrícula horizontal
        },
        ticks: {
          font: {
            family: TextVariables.Overline.fontFamily, // Cambia el tipo de letra aquí
            size: TextVariables.Overline.size, // Cambia el tamaño de letra si es necesario
            weight: TextVariables.Overline.weight, // Cambia el grosor de la letra
          },
          color: Colors.Secondary_600, // Opcional, cambia el color de la letra
        },
      },
      "y-axis-volumen": {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: yLabel,
          font: {
            family: TextVariables.Overline.fontFamily, // Cambia el tipo de letra aquí
            size: TextVariables.Overline.size, // Cambia el tamaño de letra si es necesario
            weight: TextVariables.Overline.weight, // Cambia el grosor de la letra
          },
        },
        min: 0,
        ticks: {
          callback: function (value) {
            return Number.isInteger(value) ? value : null; // Muestra solo valores enteros
          },
          font: {
            family: TextVariables.Overline.fontFamily, // Cambia el tipo de letra aquí
            size: TextVariables.Overline.size, // Cambia el tamaño de letra si es necesario
            weight: TextVariables.Overline.weight, // Cambia el grosor de la letra
          },
          color: Colors.Secondary_600, // Opcional, cambia el color de la letra
        },
      },
    },
  };

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <Line data={data} options={OptionsLine} />
    </Box>
  );
}

export default LinesChart;
