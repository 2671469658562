import React from "react";
import { Box, Typography, FormControl, Select, MenuItem, Checkbox, ListItemText } from "@mui/material";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import * as ColorsVariables from "../../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import { useTranslation } from "react-i18next";

const MultiSelector = ({ title, options, selectedValues, handleChange, width }) => {

  const [t] = useTranslation("global");

  return (
    <Box
      sx={{
        display: "flex",
        width: width,
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
      }}
    >
      <Typography
        sx={{
          color: ColorsVariables.Colors.Secondary_700,
          fontFamily: TextVariables.Caption_1,
        }}
      >
        {title}
      </Typography>
      <FormControl
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <Select
          sx={{
            width: width,
            fontFamily: TextVariables.Overline,
            minHeight: "38px",
            border: `1px solid ${ColorsVariables.Colors.Secondary_400}`,
            borderRadius: Spaces.Size_2,
            "& .MuiSelect-select": {
              padding: Spaces.Size_3,
              minHeight: "0",
            },
            "& .MuiOutlinedInput-root": {
              padding: "0px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiTypography-root": {
              lineHeight: "1",
              fontFamily: TextVariables.Overline,
              color: ColorsVariables.Colors.Secondary_400,
            },
          }}
          labelId={`${title.toLowerCase()}-select-label`}
          id={`${title.toLowerCase()}-select`}
          multiple
          value={selectedValues}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Typography
                  sx={{
                    fontFamily: TextVariables.Caption_1,
                    color: ColorsVariables.Colors.Secondary_300,
                  }}
                >
                  {`${t("follower.Select")}${title.toLowerCase()}`}
                </Typography>
              );
            }
            return selected.join(", ");
          }}
        >
          {options.map((option) => (
            <MenuItem
              sx={{
                fontFamily: "Nunito",
                color: "#736F6F",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "600",
              }}
              key={option}
              value={option}
            >
              <Checkbox checked={selectedValues.indexOf(option) > -1} />
              <ListItemText sx={{ padding: "0px" }} primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MultiSelector;

