import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { useBreakpoint } from "../../../config/BreakpointContext.js";

export const useDynamicStyles = () => {
  const { issXs, isXs, isMd } = useBreakpoint();

  return {
    settingsValuesBox: {
      display: "flex",
      flexDirection: issXs ? "column" : "row",
      gap: issXs ? Spaces.Size_2 : Spaces.Size_1,
    },

    strategySettingsLabels: {
      display: "flex",
      flexDirection: "row",
      gap: Spaces.Size_1,
      alignItems: "center",
      justifyContent: "center",
    },

    valueSettingTitle: {
      fontFamily: TextVariables.Caption_1,
      lineHeight: "1.4",
      color: Colors.Secondary_700,
    },

    labelDateTitle: {
      fontFamily: TextVariables.Overline,
      lineHeight: "1",
      color: Colors.Secondary_700,
    },

    separatorPoint: {
      fontFamily: TextVariables.Caption_2_Bold,
      lineHeight: "1.4",
      color: Colors.Secondary_400,
      display: "flex",
      alignItems: "center",
    },
  };
};
