import { Height } from "@mui/icons-material";

export const Spaces = {
    Size_1: "4px",
    Size_2: "8px",
    Size_3: "12px",
    Size_4: "16px",
    Size_5: "20px",
    Size_6: "24px",
    Size_7: "28px",
    Size_8: "32px",
    Size_9: "36px",
    Size_10: "40px",
    Size_11: "44px",
    Size_12: "48px",
    Size_13: "56px",
    Size_14: "64px",
    Size_15: "80px",
    Size_16: "96px",
    Size_17: "112px",
    Size_18: "128px",
    Size_19: "144px",
    Size_20: "160px",
    Size_21: "176px",
    Size_22: "192px",
    Size_23: "208px",
    Size_24: "224px",
    Size_25: "240px",
    Size_26: "256px",
}

export const mainBox = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "20px",
    justifyContent: "space-around",
    gap: Spaces.Size_3
};
