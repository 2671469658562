import { useState, useEffect, useCallback } from "react";
import {
  DataGrid,
  gridPageCountSelector,
  GridPagination,
  useGridApiRef,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import * as styles from "./Styles.js";

function TableComponent(props) {
  const totalElements = props.totalAmount;
  const apiRef = useGridApiRef();
  const autosizeOptions = {
    includeHeaders: true,
    expand: true,
  };

  const handleResize = useCallback(() => {
    if (apiRef.current) {
      apiRef.current.autosizeColumns(autosizeOptions);
    }
  }, [apiRef]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  function CustomPagination(props) {
    return (
      <Box sx={{height: "100%"}}>
        <Box
          sx={{
            position: "absolute",
            left: "12px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography sx={styles.Label1FooterTable}>Total:</Typography>
          <Typography sx={styles.Label2FooterTable}>
            {totalElements}
          </Typography>
        </Box>
        <GridPagination ActionsComponent={Pagination} {...props} />
      </Box>
    );
  }

  function Pagination({ className }) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "16px",
          height: "100%",
        }}
        className={className}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <MuiPagination
            shape="rounded"
            count={props.pageCount}
            page={props.page}
            onChange={(event, newPage) => {
              props.handleChangePage(newPage); // Cambiar la página actual
            }}
            sx={styles.paginationStyle}
          />
        </Box>
      </Box>
    );
  }

  return (
    <DataGrid
      apiRef={apiRef}
      rows={props.rows}
      columns={props.columns}
      onRowDoubleClick={props.handleDoubleRowClick}
      hideFooterSelectedRowCount
      pagination
      pageSize={10}
      pageSizeOptions={[5, 10]}
      slots={{ pagination: CustomPagination }}
      initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
      rowHeight={70}
      density="compact"
      autosizeOptions={autosizeOptions}
      onStateChange={handleResize}
      sx={{...styles.tableStyles, minHeight: "610px", overflowY: "hidden"}}
    />
  );
}

export default TableComponent;
