import { Button } from "@mui/material";
import * as TextVariables from "../../../assets/Variables/TextVariables";
import {Spaces} from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";

function ApplyButton({ children, HandleOnClick, width = "auto"}) {
  return (
    <Button
      onClick={HandleOnClick}
      sx={{
        width: width,
        backgroundColor: Colors.Primary_700,
        fontFamily: TextVariables.Body_3_Bold,
        color: "white",
        textTransform: "none",
        padding: `${Spaces.Size_4} ${Spaces.Size_6}`,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: Spaces.Size_2,
        "&:hover": {
          backgroundColor: "#FF0000", // Cambia el color al hacer hover
          opacity: 0.8, // Aplica opacidad al hacer hover
        },
      }}
    >
      {children}
    </Button>
  );
}

export default ApplyButton;
