import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import { borderBottom, display, height, lineHeight, minHeight, width } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex.js";



export const sideBar = {
  width: Spaces.Size_25,
  backgroundColor: "#15202A",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  boxShadow: "2px 0 5px rgba(0, 0, 0, 0.4)",
  height: "calc(100vh - 50px)",
  position: "fixed",
  top: "68px",
  left: "0",
};

export const smallSidebarContainer = {
  position: "fixed",
  width: "100%",
  height: "100%",
  zIndex: 200,
  //filter: "blur(4px)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  top: "0",
  left: "0",
};

export const closeIconContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  width: "100%",
};


export const sideBarSmall= {
  width: "243px",
  backgroundColor: "#15202A",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  boxShadow: "2px 0 5px rgba(0, 0, 0, 0.4)",
  height: "100vh",
};

export const EmptySidebar = {
  flex: 1,
  backgroundColor: "rgba(0, 0, 0, 0.2)", // Fondo semi-transparente
  backdropFilter: "blur(4px)",
};

export const nav = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: `${Spaces.Size_4} 0 ${Spaces.Size_4} 0`,
  borderBottom: `1px solid rgba(160, 175, 188, 0.35)`,
};

export const tab = {
  color: "rgba(255, 255, 255, 1)",
  width: "100%",
  textDecoration: "none",
  padding: `${Spaces.Size_4} ${Spaces.Size_7}`,
  width: "100%",
  textAlign: "left",
  display: "flex",
  alignItems: "center",
  transition: "background 0.3s ease",
  fontFamily: TextVariables.Body_4,
  gap: Spaces.Size_2,
  "&:hover": {
    backgroundColor: "#ecf1f771",
    fontFamily: TextVariables.Body_4,
    color: "#15202A",
  },
};

export const navbar = {
  zIndex: "100",
  top: "0",
  left: "0",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  padding: `${Spaces.Size_2} ${Spaces.Size_7}`,
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255)",
  boxShadow: "0px 18px 32px 0px rgba(21, 32, 42, 0.08)",
  backdropFilter: "blur(10px)",
  height: "68px",
};

export const contactIdiomContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: Spaces.Size_4,
};

export const selectStyle = {
  fontWeight: "400",
  fontSize: "12px",
  border: "none",
  borderRadius: "8px",
  fontFamily: "Plus Jakarta Sans",
  height: "33px",
  width: "172px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Elimina el borde
    },
    "&:hover fieldset": {
      border: "none", // Elimina el borde en hover
    },
    "&.Mui-focused fieldset": {
      border: "none", // Elimina el borde cuando está enfocado
    },
  },
};

export const content = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  height: "calc(100vh - 68px)",
  width: "calc(100vw - 240px)",
  marginLeft: "240px",
  overflow: "auto",
};

export const contentSmall = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  height: "calc(100svh - 68px)",
  width: "100%",
  overflow: "auto",
  minHeight: "100%",
};

export const perfilContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: Spaces.Size_4,
};

export const notificationButton = {
  "&:hover": {
    backgroundColor: Colors.Secondary_100,
  },
  "&:active": {
    transform: "scale(1)", // Evita el efecto de escalado
    boxShadow: "none", // Quita la sombra
  },
};

export const notificationMainContainer = {
  display: "flex",
  flexDirection: "column",
  padding: `${Spaces.Size_5} ${Spaces.Size_5} ${Spaces.Size_6} ${Spaces.Size_5}`,
  gap: Spaces.Size_4,
};

export const notificationTitleContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export const notificationFiltersContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export const filterButtonsContainer = {
  display: "flex",
  flexDirection: "row",
  gap: Spaces.Size_4,
};

export const notificationTitleFilterContainer = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_4,
};

export const filterNotificationButton = {
  padding: 0,
  minWidth: 0,
  textTransform: "none",
  fontFamily: TextVariables.Caption_1,
};

export const notificationList = {
  display: "flex",
  flexDirection: "column",
  padding: "0",
  fontFamily: TextVariables.Caption_1,
  height: "216px",
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    width: "8px",
    height: "4px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#555",
    },
  },
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
};

export const selectItemStyle = {
  width: "90%",
  borderBottom: `1px solid ${Colors.Gray_200}`,
  display: "flex",
  flexDirection: "column",
  padding: "16px 0px 16px 0px",
};

export const selectItemContent = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_2,
};

export const notificationBody = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export const noReadIcon = {
  height: "6px",
  width: "6px",
  padding: 0,
  backgroundColor: Colors.Info_600,
  borderRadius: "50%",
};

export const ChipBuyStyle = {
  fontFamily: TextVariables.Caption_1,
  color: Colors.Info_900,
  borderRadius: "8px",
  textTransform: "none",
  background: Colors.Info_200,
  height: "20px",
};

export const contentLogin = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "auto",
  height: "calc(100vh - 68px)",
};

export const profileBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: Spaces.Size_2,
  textTransform: "none",
  color: "Black",
};

export const profileBoxSmall = {
  display: "flex",
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: Spaces.Size_2,
  textTransform: "none",
  color: "Black",
};

export const nameBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4px",
};

export const modalProfile = {
  display: "flex",
  flexDirection: "column",
};

export const modalProfileTitle = {
  display: "flex",
  flexDirection: "row",
  gap: Spaces.Size_2,
  borderBottom: `1px solid ${Colors.Gray_200}`,
  padding: Spaces.Size_4,
};

export const profileSelector = {
  fontFamily: TextVariables.Caption_1,
  textTransform: "none",
  textAlign: "left",
  width: "100%",
  padding: Spaces.Size_4,
  color: "black",
  gap: Spaces.Size_2,
  "&:hover": {
    borderRadius: "0",
  },
};

export const infoSidebar = {
  display: "flex",
  flexDirection: "column",
  padding: `${Spaces.Size_4} ${Spaces.Size_7} ${Spaces.Size_8} ${Spaces.Size_7}`,
  alignItems: "center",
  flexShrink: 0,
  gap: Spaces.Size_3,
  borderBottom: `1px solid rgba(160, 175, 188, 0.35)`,
  marginBottom: Spaces.Size_5,
};

export const infoSidebarSmall = {
  display: "flex",
  flexDirection: "column",
  padding: `${Spaces.Size_4} ${Spaces.Size_7} ${Spaces.Size_8} ${Spaces.Size_7}`,
  alignItems: "center",
  flexShrink: 0,
  gap: Spaces.Size_3,
  borderBottom: `1px solid rgba(160, 175, 188, 0.35)`,
};

export const sidebarNameContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  gap: Spaces.Size_2,
};

export const sidebarCopyStatus = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
};

export const statusBox = {
  display: "flex",
  flexDirection: "row",
  gap: Spaces.Size_7,
};

export const statusItem = {
  display: "flex",
  flexDirection: "column",
};

export const visibilityBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  gap: Spaces.Size_2,
};

export const socialCloseButon = {
  backgroundColor: Colors.Secondary_900,
  color: "white",
  textTransform: "none",
  padding: `${Spaces.Size_3}`,
  gap: Spaces.Size_2,
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${Colors.Secondary_900}`,
  borderRadius: Spaces.Size_2,
  lineHeight: "1",
  minWidth: "0px",
  minHeight: "0px",
  width: "37.6px",
  height: "37.6px",
  "&:hover": {
    backgroundColor: Colors.Secondary_900, // Cambia el color al hacer hover
    opacity: 0.8, // Aplica opacidad al hacer hover
  },
};

export const socialSubEmptyContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: Spaces.Size_7,
  alignSelf: "stretch",
};

export const messageListBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: Spaces.Size_4,
  height: "300px",
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    width: "4px",
    height: "4px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#555",
    },
  },
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
};

export const messageListBox2 = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: Spaces.Size_4,
  height: "350px",
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    width: "4px",
    height: "4px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#555",
    },
  },
  "::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
};

export const socialTitle = {
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: Spaces.Size_4,
  flex: "1 0 0",
};

export const newMessageTitle = {
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: Spaces.Size_7,
  flex: "1 0 0",
};

export const mainMessageBox = {
  display: "flex",
  flexDirection: "column",
  padding: Spaces.Size_3,
  alignItems: "flex-start",
  gap: Spaces.Size_1,
  borderRadius: Spaces.Size_3,
  border: `1px solid ${Colors.Secondary_50}`,
  width: "calc(100% - 4px)",
  marginRight: Spaces.Size_1,
  background: "rgba(236, 241, 247, 0.50)",
};

export const messagesBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  gap: Spaces.Size_3,
  marginRight: Spaces.Size_1,
  borderBottom: `1px solid ${Colors.Secondary_50}`,
};

export const messageButtonsBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "100%",
  gap: Spaces.Size_3,
};

export const socialEmptyContainer = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  gap: Spaces.Size_3,
};

export const messageListMainBox = {
  display: "flex",
  width: "100%",
  height: "100%",
  flexDirection: "column",
  gap: Spaces.Size_3,
  justifyContent: "space-between",
};

export const messageTitleBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
};

export const messageDateBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: Spaces.Size_1,
};

export const contentMessageBox = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_2,
};

export const addMessageButtonBox = {
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "flex-end",
};

export const newMessageBox = {
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "flex-end",
};

export const newMessageButtonsBox = {
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: Spaces.Size_5,
};

export const valueSettingTitle = {
  fontFamily: TextVariables.Caption_2_Bold,
  lineHeight: "1.4",
  color: Colors.Secondary_300,
};

export const labelDateTitle = {
  fontFamily: TextVariables.Overline,
  lineHeight: "1",
  color: Colors.Secondary_700,
};

export const separatorPoint = {
  fontFamily: TextVariables.Caption_2_Bold,
  lineHeight: "1.4",
  color: Colors.Secondary_400,
  display: "flex",
  alignItems: "center",
};
