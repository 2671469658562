
import { Box, Select, MenuItem, styled, Typography } from "@mui/material";
import * as styles from "../../NavbarSidebar/Styles.js";
import * as masterData from "../../../Master Data/masterData.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import {Spaces} from "../../../assets/Variables/SpaceVariables";
import Colors from "../../../assets/Variables/ColorsVariables.js";

const LanguageSelector = () => {
  const [languageSelect, setLanguageSelect] = useState("en");

  const { i18n } = useTranslation();
  const cookies = new Cookies();

  const changeLang = (e) => {
    i18n.changeLanguage(e.target.value);
    cookies.set("lang", e.target.value, { path: "/" });
    setLanguageSelect(e.target.value);
  };

  const CustomSelect = styled(Select)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      padding: "0px 8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-select": {
      display: "flex !important",
      flexDirection: "row",
      alignItems: "center",
      padding: `${Spaces.Size_3} ${Spaces.Size_4}`,
      transition: "background-color 0.3s", // Añade una transición suave
    },
    "&:hover .MuiSelect-select": {
      backgroundColor: Colors.Secondary_100, // Color de fondo al hacer hover
    },
  }));

  return (
    <Box sx={styles.contactIdiomContainer}>
      <Box>
        <CustomSelect value={languageSelect} onChange={changeLang}>
          {masterData.languages.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <img
                src={option.flag}
                alt="France Flag"
                style={{ width: "20px", height: "15px", marginTop: "3px" }}
              />
              <Typography sx={{ marginLeft: "8px", fontFamily: TextVariables.Body_4, lineHeight: "2" }}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </CustomSelect>
      </Box>
    </Box>
  );
};


export default LanguageSelector;
