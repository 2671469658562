import englishFlag from "../assets/logos/English.svg";
import spanishFlag from "../assets/logos/spanish.svg";
import DEFAULTFlag from "../assets/logos/Currencies/informacion.jpg"
import EURFlag from "../assets/logos/Currencies/EUR.svg";
import USDFlag from "../assets/logos/Currencies/USD.svg";
import GBPFlag from "../assets/logos/Currencies/Currency=GBP.svg";
import JPYFlag from "../assets/logos/Currencies/Currency=JPY.svg";
import CHFFlag from "../assets/logos/Currencies/Currency=CHF.svg";
import CADFlag from "../assets/logos/Currencies/Currency=CAD.svg";
import NZDFlag from "../assets/logos/Currencies/Currency=NZD.svg";
import AUDFlag from "../assets/logos/Currencies/Currency=AUD.svg";
import CZKFlag from "../assets/logos/Currencies/Currency=CZK.svg";
import DKKFlag from "../assets/logos/Currencies/Currency=DKK.svg";
import HKDFlag from "../assets/logos/Currencies/Currency=HKD.svg";
import HUFFlag from "../assets/logos/Currencies/Currency=HUF.svg";
import MXNFlag from "../assets/logos/Currencies/Currency=MXN.svg";
import NOKFlag from "../assets/logos/Currencies/Currency=NOK.svg";
import PLNFlag from "../assets/logos/Currencies/Currency=PLN.svg";
import SEKFlag from "../assets/logos/Currencies/Currency=SEK.svg";
import SGDFlag from "../assets/logos/Currencies/Currency=SGD.svg";
import TRYFlag from "../assets/logos/Currencies/Currency=TRY.svg";
import ZARFlag from "../assets/logos/Currencies/Currency=ZAR.svg";
import ILSFlag from "../assets/logos/Currencies/Currency=ILS.svg";
import RUBFlag from "../assets/logos/Currencies/Currency=RUB.svg";
import BTCFlag from "../assets/logos/Currencies/Currency=BTC.svg";
import ETHFlag from "../assets/logos/Currencies/Currency=ETH.svg";
import LTCFlag from "../assets/logos/Currencies/Currency=LTC.svg";
import XRPFlag from "../assets/logos/Currencies/Currency=XRP.svg";
import ADAFlag from "../assets/logos/Currencies/Currency=ADA.svg";
import BCHFlag from "../assets/logos/Currencies/Currency=BCH.svg";
import DOGFlag from "../assets/logos/Currencies/Currency=DOG.svg";
import DOTFlag from "../assets/logos/Currencies/Currency=DOT.svg";
import EOSFlag from "../assets/logos/Currencies/Currency=EOS.svg";
import LNKFlag from "../assets/logos/Currencies/Currency=LNK.svg";
import XLMFlag from "../assets/logos/Currencies/Currency=XLM.svg";
import RPLFlag from "../assets/logos/Currencies/Currency=RPL.svg";
import XCUFlag from "../assets/logos/Currencies/Currency=XCU.svg";
import CNHFlag from "../assets/logos/Currencies/Currency=CNH.svg";
import XAUFlag from "../assets/logos/Currencies/Currency=XAU.svg";
import XAGFlag from "../assets/logos/Currencies/Currency=XAG.svg";


export const languages = [
  { label: "ENG", value: "en", flag: englishFlag },
  // { label: "ESP", value: "es", flag: spanishFlag },
];

export const assets = [
  { label: "defaultFlagPath", value: "defaultFlagPath", flag: DEFAULTFlag },
  { label: "EUR", value: "EUR", flag: EURFlag },
  { label: "USD", value: "USD", flag: USDFlag },
  { label: "GBP", value: "GBP", flag: GBPFlag },
  { label: "JPY", value: "JPY", flag: JPYFlag },
  { label: "CHF", value: "CHF", flag: CHFFlag },
  { label: "CAD", value: "CAD", flag: CADFlag },
  { label: "NZD", value: "NZD", flag: NZDFlag },
  { label: "AUD", value: "AUD", flag: AUDFlag },
  { label: "CZK", value: "CZK", flag: CZKFlag },
  { label: "DKK", value: "DKK", flag: DKKFlag },
  { label: "HKD", value: "HKD", flag: HKDFlag },
  { label: "HUF", value: "HUF", flag: HUFFlag },
  { label: "MXN", value: "MXN", flag: MXNFlag },
  { label: "NOK", value: "NOK", flag: NOKFlag },
  { label: "PLN", value: "PLN", flag: PLNFlag },
  { label: "SEK", value: "SEK", flag: SEKFlag },
  { label: "SGD", value: "SGD", flag: SGDFlag },
  { label: "TRY", value: "TRY", flag: TRYFlag },
  { label: "ZAR", value: "ZAR", flag: ZARFlag },
  { label: "ILS", value: "ILS", flag: ILSFlag },
  { label: "RUB", value: "RUB", flag: RUBFlag },
  { label: "BTC", value: "BTC", flag: BTCFlag },
  { label: "ETH", value: "ETH", flag: ETHFlag },
  { label: "LTC", value: "LTC", flag: LTCFlag },
  { label: "XRP", value: "XRP", flag: XRPFlag },
  { label: "ADA", value: "ADA", flag: ADAFlag },
  { label: "BCH", value: "BCH", flag: BCHFlag },
  { label: "DOG", value: "DOG", flag: DOGFlag },
  { label: "DOT", value: "DOT", flag: DOTFlag },
  { label: "EOS", value: "EOS", flag: EOSFlag },
  { label: "LNK", value: "LNK", flag: LNKFlag },
  { label: "XLM", value: "XLM", flag: XLMFlag },
  { label: "RPL", value: "RPL", flag: RPLFlag },
  { label: "XCU", value: "XCU", flag: XCUFlag },
  { label: "CNH", value: "CNH", flag: CNHFlag },
  { label: "XAU", value: "XAU", flag: XAUFlag },
  { label: "XAG", value: "XAG", flag: XAGFlag },
];


export const symbolOptions = [
  { id: "usd-symbol", value: "usd", label: "USD" },
  { id: "gbp-symbol", value: "gbp", label: "GBP" },
];

export const roleOptions = [
  { id: "follower", value: "follower", label: "Follower" },
  { id: "signalProvider", value: "signal", label: "Signal Provider" },
];

export const platformOptions = [
  { id: "metatrader4", value: "metatrader4", label: "Metatrader 4" },
  { id: "metatrader5", value: "metatrader5", label: "Metatrader 5" },
];

export const serverOptionsMt4 = [
  {
    id: "demo",
    label: "Demo",
    value: "https://communitraders-mt4-demo.tradeviewforex.com",
  },
  {
    id: "live1",
    label: "Live 1",
    value: "https://communitraders-mt4-live-1.tradeviewforex.com",
  },
  {
    id: "live2",
    label: "Live 2",
    value: "https://communitraders-mt4-live-2.tradeviewforex.com",
  }
];

export const serverOptionsMt5 = [
  {
    id: "demo",
    label: "Demo",
    value: "https://communitraders-mt5-demo.tradeviewforex.com",
  },
  {
    id: "live",
    label: "Live",
    value: "https://communitraders-mt5-live.tradeviewforex.com",
  }
];

export const assetsTypes = [
  { id: "forex", label: "Forex", value: "Forex" },
  { id: "cfd", label: "CFDs", value: "CFDs" },
  {
    id: "cryptocurrencies",
    label: "cryptocurrencies",
    value: "cryptocurrencies",
  },
  { id: "metals", label: "Metals", value: "Metals" },
];

export const leverageOptions = [
  { id: "1-1", value: "1", label: "1-1" },
  { id: "1-2", value: "2", label: "1-2" },
  { id: "1-3", value: "3", label: "1-3" },
  { id: "1-5", value: "5", label: "1-5" },
  { id: "1-10", value: "10", label: "1-10" },
  { id: "1-15", value: "15", label: "1-15" },
  { id: "1-25", value: "25", label: "1-25" },
  { id: "1-33", value: "33", label: "1-33" },
  { id: "1-50", value: "50", label: "1-50" },
  { id: "1-66", value: "66", label: "1-66" },
  { id: "1-75", value: "75", label: "1-75" },
  { id: "1-100", value: "100", label: "1-100" },
  { id: "1-125", value: "125", label: "1-125" },
  { id: "1-150", value: "150", label: "1-150" },
  { id: "1-175", value: "175", label: "1-175" },
  { id: "1-200", value: "200", label: "1-200" },
  { id: "1-300", value: "300", label: "1-300" },
  { id: "1-400", value: "400", label: "1-400" },
  { id: "1-500", value: "500", label: "1-500" },
  { id: "1-1000", value: "1000", label: "1-1000" },
];

export const accountTypes = [
  { id: "ilc", value: "ilc", label: "ILC" },
  { id: "fx", value: "fx", label: "FX" },
];

export const strategyTypes = [
  { id: "human-strategy", value: "Human", label: "Human" },
  {
    id: "expertAdvisor-strategy",
    value: "ExpertAdvisor",
    label: "Expert Advisor",
  },
  { id: "mixed-strategy", value: "Mixed", label: "Expert Advisor supervised" },
];

export const ddTypes = [
  { id: "no-drawdown-dd", value: "NoDrawdown", label: "No drawdown" },
  { id: "in-equity-dd", value: "inEquity", label: "In Equity" },
];

export const frequencyTime = [
  { label: "Monthly", value: "monthly" },
  { label: "Yearly", value: "yearly" },
];

export const months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const monthsSimply = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const years = [
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
  { label: "2026", value: "2026" },
];

export const ddProtectionModes = [
  { label: "No drawdown", value: "NoDrawdown" },
  { label: "In equity", value: "InEquity" },
  { label: "In Balance", value: "InBalance"}
];

export const copyMode = [
  { label: "Proportional", value: "Proportional" },
  { label: "Fixed lots", value: "FixedLots" },
];

export const booleanOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
