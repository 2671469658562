export const Caption_1 ={
    fontWeight: '400', 
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px"
}

export const Caption_bold_1 ={
    fontWeight: '700', 
    fontFamily:"Plus Jakarta Sans",
    fontSize: "12px"
}

export const Caption_0_bold ={
    fontWeight: '900', 
    fontFamily:"Plus Jakarta Sans",
    fontSize: "10px"
}

export const Body_4 ={
    fontWeight: '400', 
    fontFamily:"Plus Jakarta Sans",
    fontSize: "14px"
}

export const Body_3_bold ={
    fontWeight: '600', 
    fontFamily:"Plus Jakarta Sans",
    fontSize: "17px"
}

export const Subtitle_4 ={
    fontWeight: '600', 
    fontFamily:"Plus Jakarta Sans",
    fontSize: "22px"
}
export const H1 = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "103px"
};

export const H2 = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "64px"
};

export const H3 = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "51px"
};

export const H4 = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "22px"
};

export const Subtitle_1 = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "36px"
};

export const Subtitle_2 = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "2px"
};

export const Subtitle_3 = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "26px"
};

export const Body_1 = {
    fontWeight: '400', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "26px"
};

export const Body_2 = {
    fontWeight: '400', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "22px"
};

export const Body_3 = {
    fontWeight: '400', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "17px"
};

export const Body_1_Bold = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "26px"
};

export const Body_2_Bold = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "22px"
};

export const Body_3_Bold = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "17px"
};

export const Body_4_Bold = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px"
};

export const Link_1 = {
    fontWeight: '400', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "26px"
};

export const Link_2 = {
    fontWeight: '400', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "21px"
};

export const Link_3 = {
    fontWeight: '400', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "17px"
};

export const Link_4 = {
    fontWeight: '400', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px"
};

export const Link_5 = {
    fontWeight: '400', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "12px"
};

export const Link_6 = {
    fontWeight: '400', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "11px"
};

export const Caption_2 = {
    fontWeight: '400', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "11px"
};

export const Caption_1_Bold = {
    fontWeight: '700', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "12px"
};

export const Caption_2_Bold = {
    fontWeight: '600', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "11px"
};

export const Overline = {
    fontWeight: '400', 
    fontFamily: "Plus Jakarta Sans",
    fontSize: "10px"
};
