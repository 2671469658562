import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import ColorsVariables from "../../../assets/Variables/ColorsVariables.js";
import { useBreakpoint } from "../../../config/BreakpointContext.js";


export const useDynamicStyles = () => {
  const { isXs, isMd } = useBreakpoint();

  return {
    BoxPrincipalStyle : {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: isMd ? "calc(100vw - 240px)" : "100vw",
      maxWidth: "1400px",
      gap: Spaces.Size_3,
      padding: isMd ? Spaces.Size_10 : Spaces.Size_5,
    },
    
    FilterComponentStyle : {
      maxHeight: "20vh",
    },
    
    GridContainerStyle : {
      margin: "0px",
      overflow: "auto",
      padding: "13px 0px 13px 0px",
      justifyContent: "space-around",
      flexWrap: "wrap",
      "::-webkit-scrollbar": {
        width: "8px",
        height: "4px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "#555",
        },
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
    },
    
    CardStyle : {
      width: 290,
      padding: "20px",
      background:
        "linear-gradient(156deg, rgba(255, 255, 255, 0.35)10.28%, rgba(255, 255, 255, 0.00)106.66%)",
      gap: "18px",
      border: "1px solid rgba(255, 18, 246, 0.06)",
      borderRadius: "16px",
      boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.13)",
      transition: "border-color 0.3s ease-in-out, background 0.3s",
      "&:hover": {
        background: "white",
        borderColor: ColorsVariables.Primary_400,
      },
    },
    
    FollowCardStyle : {
      width: 290,
      padding: "20px",
      background:
        "linear-gradient(156deg, rgba(255, 255, 255, 0.35)10.28%, rgba(255, 255, 255, 0.00)106.66%)",
      gap: "18px",
      border: "4px solid rgba(255, 18, 246, 0.08)",
      borderRadius: "16px",
      boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.13)",
      transition: "border-color 0.3s ease-in-out, background 0.3s",
      "&:hover": {
        background: "white",
        borderColor: ColorsVariables.Primary_400,
      },
    },
    
    ZoneAvatarAndTotalReturn : {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: "12px",
    },
    
    ZoneAvatar : {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      alignItems: "center",
    },
    
    AvatarStyle : {
      width: "44px",
      height: "44px",
      borderRadius: "100px"
    },
    
    AccountNameStyle : {
      overflow: "hidden",
      fontFamily: TextVariables.Body_4_Bold,
      color: ColorsVariables.Secondary_900,
      maxWidth: "120px", // Define el ancho máximo
      whiteSpace: "nowrap", // Evita que el texto se divida en varias líneas
      textOverflow: "ellipsis", // Añade puntos suspensivos si el texto es largo
    },
    
    ZoneTotalReturn : {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "57px",
      height: "28px",
    },
    
    TotalReturnStyle : {
      fontFamily: TextVariables.Overline,
      color: ColorsVariables.Secondary_700,
    },
    
    TotalReturnBoxStyle : {
      display: "flex",
      padding: "5px var(--Size-2, 8px)",
      alignItems: "center",
      gap: "2px",
      borderRadius: "7.3px",
      background: ColorsVariables.Success_50,
    },
    
    TotalReturnFontStyle : {
      fontFamily: "Plus Jakarta Sans",
      fontWeight: 400,
      fontSize: "10px",
      color: ColorsVariables.Success_900,
    },
    
    ZoneElements : {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    
    ComboBasicElements : {
      display: "flex",
      justifyContent: "space-between",
      padding: " 6px 12px",
    },
    
    BasicCardBox : {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      gap: "2px",
    },
    
    BasicCardTypoBox : {
      fontFamily: TextVariables.Overline,
      color: ColorsVariables.Secondary_700,
    },
    
    BasicCardValueBox : {
      fontFamily: "Plus Jakarta Sans",
      fontWeight: 600,
      fontSize: "10px",
      color: ColorsVariables.Secondary_700,
    },
    
    RiskScoreBox : {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: " 8px 12px",
      alignSelf: "stretch",
    },
    
    ButtonDiscoverMoreStyle : {
      display: "flex",
      width: "99px",
      height: "100%",
      minWidth: "56px",
      padding: "5px",
      justifyContent: "center",
      alignItems: "center",
      color: "#15202A",
      textAlign: "center",
      fontFamily: TextVariables.Caption_1_Bold,
      textTransform: "none",
    },
    
    ButtonFollowStyle : {
      display: "flex",
      width: "83px",
      height: "100%",
      minWidth: "56px",
      padding: "12px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      border: "1px solid #15202A",
      color: "#15202A",
      textAlign: "center",
      fontFamily: TextVariables.Caption_1_Bold,
      textTransform: "none",
    },
    
    emptyFieldLabel : {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: Spaces.Size_1,
    },
    
    customFormContainer : {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: Spaces.Size_7,
    },
    
    tooltipCopyModeBox : {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: Spaces.Size_2,
    },
    
    tooltipCopyModeContentBox : {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: Spaces.Size_2,
    },
    
    strategyCardsBox : {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: Spaces.Size_4,
    },
    
    followButton : {
      display: "flex",
      minWidth: "83px",
      height: "100%",
      padding: "12px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      border: "1px solid #15202A",
      color: "#15202A",
      textAlign: "center",
      fontFamily: TextVariables.Caption_1_Bold,
      textTransform: "none",
    },
    
    unFollowButton : {
      display: "flex",
      height: "100%",
      minWidth: "83px",
      padding: "12px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      color: "#FFF",
      textAlign: "center",
      fontFamily: TextVariables.Caption_1_Bold,
      textTransform: "none",
      background: "red",
      "&:hover": {
        backgroundColor: "#FF0000", // Cambia el color al hacer hover
        opacity: 0.8, // Aplica opacidad al hacer hover
      },
    },
    
    Label1FooterTable : {
      fontFamily: TextVariables.Caption_2, 
      color: ColorsVariables.Secondary_400,
      marginRight: "5px"
    },
    
    Label2FooterTable : {
      fontFamily: TextVariables.Caption_2_Bold, 
      color: ColorsVariables.Secondary_900,
    },
    
    paginationStyle : {
      '& .MuiPaginationItem-root': {
          background: "transparent",
          padding:'12px',
          color: '#43586B',
          fontFamily: "Plus Jakarta Sans",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
      },
      '& .MuiPaginationItem-root.Mui-selected': {
          background: "#ECF1F7",
      },
    },
    
    performanceBox : {
      display: "flex",
      width: "100%",
      flex: "1 0 0",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: Spaces.Size_5,
    },
  }
}
// DISCOVER SCREEN

// FILTER COMPONENT
