import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { useBreakpoint } from "../../../config/BreakpointContext.js";

export const useDynamicStyles = () => {
  const { isXs, isMd } = useBreakpoint();

  return {
    mainPaper: {
      display: "flex",
      flexDirection: "column",
      padding: Spaces.Size_6,
      width: isMd ? "calc(100vw - 280px)" : "95vw",
      maxWidth: "2000px",
      // alignItems:"flex-start",
      gap: Spaces.Size_6,
      borderRadius: Spaces.Size_5,
      border: `1px solid rgba(255, 18, 246, 0.06)`,
      background:
        "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%)",
      boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
      backdropFilter: "blur(20px)",
    },

    searchContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },

    searchInput: {
      width: "263px",
      maxHeight: "38px",
      padding: "0px",
      fontFamily: TextVariables.Overline,
      border: `1px solid ${Colors.Secondary_400}`,
      borderRadius: "8px",
      "& .MuiOutlinedInput-root": {
        "& .MuiInputBase-input": {
          padding: Spaces.Size_3,
          fontFamily: TextVariables.Overline,
          color: Colors.Secondary_900,
        },
      },
    },

    dateInputBox: {
      display: "flex",
      flexDirection: "row",
      gap: Spaces.Size_2,
    },

    searchButtonContainer: {
      display: "flex",
      flexDirection: "row",
      gap: Spaces.Size_2,
    },

    ChipHumanStyle: {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      color: Colors.Success_900,
      borderRadius: "8px",
      textTransform: "none",
      background: Colors.Success_50,
      height: "23px",
      width: "auto",
    },
    ChipSupervisedStyle: {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      color: Colors.Info_900,
      borderRadius: "8px",
      textTransform: "none",
      background: Colors.Info_200,
      height: "23px",
    },

    ChipRobotStyle: {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      color: Colors.Warning_900,
      borderRadius: "8px",
      textTransform: "none",
      background: Colors.Warning_100,
      height: "23px",
    },

    tooltipBox: {
      display: "flex",
      flexDirection: "row",
      gap: Spaces.Size_1,
      alignItems: "center",
      justifyContent: "center",
    },

    settingsFollowingBox: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  };
};
