import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import TableToolTip from "../../Generals/Tooltips/TableToolTip.js";

const TitledInput = ({
  name,
  title,
  value,
  inputProps= {},
  handleChange,
  type = "text",
  placeholder = "",
  error,
  helperText,
  tooltext = "",
  disabled = false,
}) => {
  const inputStyle = {
    fontFamily: TextVariables.Body_4,
    borderRadius: Spaces.Size_2,
    width: "100%",
    padding: "0px",
    border: error
      ? `1px solid ${Colors.Error_700}`
      : disabled
      ? `1px solid ${Colors.Gray_200}`
      : `1px solid ${Colors.Secondary_700}`,
    "& .MuiOutlinedInput-root": {
      borderRadius: Spaces.Size_2, // Ajusta el valor según tus necesidades
    },
    "& .MuiInputBase-input": {
      fontFamily: TextVariables.Body_4,
      padding: Spaces.Size_4,
    },
    "& .MuiOutlinedInput-notchedOutline": {},
  };
  return (
    <div>
      <Box
        sx={{
          marginRight: "10px",
          marginBottom: "5px",
          display: "flex",
          flexDirection: "row",
          gap: Spaces.Size_1,
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: TextVariables.Body_4,
            color: Colors.Secondary_700,
          }}
        >
          {title}
        </Typography>
        {tooltext !== "" && (
          <TableToolTip title={tooltext}>
            <Box sx={{display: "flex", height: "12px", alignItems: "center", marginTop: "2px"}}>
              <span
                style={{ fontSize: Spaces.Size_3 }}
                className="material-symbols-outlined"
              >
                help
              </span>
            </Box>
          </TableToolTip>
        )}
      </Box>

      <TextField
        name={name}
        value={value}
        onChange={handleChange}
        type={type}
        required
        sx={inputStyle}
        placeholder={placeholder}
        inputProps={{
          ...inputProps,
          sx: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none", // Estilo adicional
            },
          },
        }}
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};

export default TitledInput;
