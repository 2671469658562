import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { Height } from "@mui/icons-material";
import { Backdrop } from "@mui/material";

export const form = {
  display: "flex",
  flexDirection: "column",
  width: "calc(100vw - 280px)",
  maxWidth: "1110px",
  height: "calc(100vh - 100px)",
  maxHeight: "668px",
  padding: Spaces.Size_6,
  alignItems: "center",
  justifyContent: "center",
  borderRadius: Spaces.Size_5,
  border: "1px rgba(255, 255, 255, 0.42)",
  background:
    "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%)",
  boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
  backdropFilter: "blur(20px)",
  position: "relative",
};

export const noContentBox = {
    display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  gap: Spaces.Size_7

}

export const noContentBoxEmptyText = {
    display: "flex",
    flex: "1 0 0",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  justifyContent: "center"
}
