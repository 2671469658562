import { Box, Typography } from "@mui/material";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";

export function InfoChip({ text }) {
  return (
    <Box
      sx={{
        display: "flex",
        padding: `${Spaces.Size_1} ${Spaces.Size_2}`,
        alignItems: "center",
        gap: "2px",
        borderRadius: Spaces.Size_2,
        background: Colors.Success_50
      }}
    >
      <Typography sx={{ fontFamily: TextVariables.Caption_1, color: Colors.Success_900, lineHeight: 1 }}>{text}</Typography>
    </Box>
  );
}

export default InfoChip;
