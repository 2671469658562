import { Box, Typography } from "@mui/material";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import colombiaLogo from "../../../assets/colombia.png";

export function InfoDetailLogoBox({ title, detail, asset1, asset2 }) {
  return (
    <Box
      sx={{
        display: "flex",
        padding: Spaces.Size_4,
        justifyContent: "space-between",
        alignItems: "center",
        flex: "1 0 0",
        borderRadius: Spaces.Size_3,
        border: "1px solid rgba(255, 18, 246, 0.06)",
        background: "#fff"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: Spaces.Size_2,
        }}
      >
        <Typography
          sx={{
            fontFamily: TextVariables.Overline,
            textAlign: "right",
            lineHeight: "1",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: TextVariables.Caption_1_Bold,
            textAlign: "right",
            lineHeight: "1",
          }}
        >
          {detail}
        </Typography>
      </Box>
      <Box sx={{display: "flex", alignItems: "center", }}>
        <Box
          component="img"
          src={asset1}
          alt="asset 1"
          sx={{
            width: "22px",
            height: "22px",
            marginRight: "-8px",
            borderRadius: "50%"
          }}
        />
        <Box
          component="img"
          src={asset2}
          alt="asset 2"
          sx={{
            width: "22px",
            height: "22px",
            borderRadius: "50%"
          }}
        />
      </Box>
    </Box>
  );
}

export default InfoDetailLogoBox;
