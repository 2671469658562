import React, { useState, useEffect } from "react";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import * as styles from "./Styles.js";
import Avatar from "@mui/material/Avatar";
import { Box, Button, Popover, Typography, Badge, Chip } from "@mui/material";

import TextIconButton from "../Generals/Buttons/TextIconButton.js";
import ShareLinkModal from "../Generals/Notifications/ShareLinkModal.js";
import * as Encryptor from "../../Tools/AESEncrypter";
import signalService from "../../services/signalProvider.js";


const SocialHubFollower = ({ followerInformation, signalImage ,signalLogin}) => {
  //#region "Instantiation of States"
  const [openSocial, setOpenSocial] = useState(true);
  const [openSocialModal, setOpenSocialModal] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [strategyInformation, setStrategyInformation] = useState(undefined);
  const [messages, setMessages] = useState([]);
  const openSocialBool = Boolean(openSocialModal);
  const idSocial = openSocialBool ? "social-popover" : undefined;

  function formatDateTime(dateString, format) {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('.');
    const [hours, minutes] = timePart.split(':');
    const date = new Date(year, month - 1, day, hours, minutes);
  
    if (format === "hour") {
      let hour = date.getHours();
      const ampm = hour >= 12 ? 'pm' : 'am';
      hour = hour % 12 || 12;
      const minute = date.getMinutes().toString().padStart(2, '0');
      return `${hour}:${minute} ${ampm}`;
    }
    if (format === "date") {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    }
    return "Invalid format";
  } 
  //#endregion

  //#region "Handlers"
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleCopy = () => {
    const encryptString = Encryptor.encrypt(
      strategyInformation.signal_followed
    );
    const urlSafeEncrypt = Encryptor.makeUrlSafe(encryptString);
    const textToCopy = `${process.env.REACT_APP_URL}login/${urlSafeEncrypt}`; // Texto que deseas copiar
    navigator.clipboard.writeText(textToCopy);
  };
  const handleOpenSocial = (event) => {
    setOpenSocial(!openSocial);

    // Establece el botón como el elemento de anclaje solo si se abre el popover
    if (!openSocial) {
      setOpenSocialModal(null);
    } else {
      setOpenSocialModal(event.currentTarget);
      signalService.MarkReadMessages();
      if(strategyInformation)
        strategyInformation.pending_messages = 0;
    }
  };
  //#endregion

  //#region "Requests"
  const RequestChatMessages = async (signalAcc) => {
    try {
      const response = await signalService.getChats(signalAcc);
      if (response.data != null) {
        setMessages(response.data.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  //#region "useEffects"
  useEffect(() => {
    if(followerInformation){
      setStrategyInformation(followerInformation);
      RequestChatMessages(followerInformation.signal_followed);
    }
  }, [followerInformation]);

  useEffect(() => {
    if (followerInformation) {
      setStrategyInformation(followerInformation);
      RequestChatMessages(followerInformation.signal_followed);
    }
  }, []);
  //#endregion

  //#region "HTML"
  return (
    <Box>
      <ShareLinkModal
        open={openModal}
        onClose={handleCloseModal}
        handleCopy={handleCopy}
        title="Share this signal provider's profile"
      ></ShareLinkModal>
      <Box
        sx={{
          position: "fixed", // Fija el botón en la pantalla
          bottom: 17, // Margen inferior
          right: 16, // Margen izquierdo
          zIndex: 1000, // Asegura que esté por encima de otros elementos
        }}
      >
        <TextIconButton
          HandleOnClick={handleOpenSocial}
          logo={openSocial ? "forum" : undefined}
          label={openSocial ? <>
            <>Social Hub&nbsp;&nbsp;&nbsp;</>
            <Chip 
              label = {(strategyInformation) ? strategyInformation.pending_messages : "0"}
              size="small"
              sx={{
                fontSize:'10px',
                background:'#FFE5E5',
                height:'14px',
                marginTop:'-2px'
              }}/></> : "X"}
          color="white"
          background={Colors.Secondary_900}
        ></TextIconButton>
      </Box>
      <Popover
        id={idSocial}
        open={openSocialBool}
        anchorEl={openSocialModal}
        onClose={handleOpenSocial}
        anchorOrigin={{
          vertical: "top", // Posiciona el popover sobre el botón
          horizontal: "right", // Alineado al borde derecho
        }}
        transformOrigin={{
          vertical: "bottom", // La esquina inferior del popover se alineará con la esquina superior del botón
          horizontal: "right",
        }}
        disablePortal
        PaperProps={{
          sx: {
            width: "400px",
            display: "flex",
            flexDirection: "column",
            padding: Spaces.Size_6,
            gap: Spaces.Size_7,
            borderRadius: Spaces.Size_5,
            boxShadow: "0px 0px 16px 0px rgba(21, 32, 42, 0.10)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: Spaces.Size_2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            className="material-symbols-outlined"
            style={{ fontSize: Spaces.Size_7, lineHeight: 1 }}
          >
            forum
          </span>
          <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
            Social Hub
          </Typography>
        </Box>

        <Box sx={styles.messageListMainBox}>
          {strategyInformation === undefined && (
            <Typography sx={{fontFamily: TextVariables.Caption_1, textAlign: "center"}}>You are not following any strategy</Typography>
          )}
          {strategyInformation !== undefined && (
            <Box sx={styles.messageListBox2}>
              {messages.map((item, index) => (
                <Box key={index} sx={styles.mainMessageBox}>
                  <Box sx={styles.messagesBox}>
                    <Avatar
                      alt="strategy image"
                      src={signalImage}
                      sx={{ width: Spaces.Size_11, height: Spaces.Size_11 }}
                    ></Avatar>
                    <Box sx={styles.contentMessageBox}>
                      <Box sx={styles.messageTitleBox}>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1_Bold,
                            lineHeight: 1.2,
                          }}
                        >
                          {strategyInformation.strategy_followed}
                        </Typography>
                        <Box sx={styles.messageDateBox}>
                          <Typography sx={styles.labelDateTitle}>
                            {formatDateTime(item.dateTime, "hour")}
                          </Typography>
                          <Typography sx={styles.separatorPoint}>
                            &#8226;
                          </Typography>
                          <Typography sx={styles.labelDateTitle}>
                            {formatDateTime(item.dateTime, "date")}
                          </Typography>
                        </Box>
                      </Box>

                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_2,
                          lineHeight: "1.4",
                        }}
                      >
                        {item.message}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={styles.messageButtonsBox}>               
                    <Button
                      onClick={handleOpenModal}
                      sx={{
                        display: "inline-block",
                        color: "black",
                        padding: "0px",
                        minWidth: "auto",
                        lineHeight: "1",
                        height: Spaces.Size_4,
                      }}
                    >
                      {/* <span
                        className="material-symbols-outlined"
                        style={{
                          fontSize: Spaces.Size_4,
                          lineHeight: "1",
                          color: Colors.Secondary_500,
                        }}
                      >
                        share
                      </span> */}
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  );
  //#endregion
};

export default SocialHubFollower;
