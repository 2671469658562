import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Typography, Box } from "@mui/material";
import * as styles from "../Charts/Styles.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import { useDynamicStyles } from "./Styles.js";


// Registrar los elementos necesarios de chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const CircularChart = ({ value, maxValue, totalAmount }) => {
  const { isXs, isMd } = useBreakpoint();
  const styles = useDynamicStyles();
  const data = {
    datasets: [
      {
        data: [value, maxValue - value],
        backgroundColor: [Colors.Error_500, Colors.Secondary_900],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: '87%', // Ajusta el corte interno del gráfico para dejar espacio en el centro
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-end",
      position: "relative",
      width: isMd ? "100%" : "100px",
      height: isMd ? "100%" : "100px",
      gap: "20px",
    }}>
      <Doughnut data={data} options={options} />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <Typography sx={{fontFamily: TextVariables.H4}}>
          {`${totalAmount}`}
        </Typography>
        <Typography sx={{fontFamily: TextVariables.Caption_1}}>
          Total
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularChart;
