import { Grid } from "@mui/material";
import { InfoDetailBox } from "../../Generals/Cards/infoDetailBox.js";
import { RiskDetailBox } from "../../Generals/Cards/infoDetailRiskBox.js";
import { useStatus } from "../../../config/BreakpointContext.js";

export function StatusModule() {
  const { accountStatus } = useStatus();
  return (
    <Grid container spacing={2} sx={{ minHeight: "auto" }}>
      {accountStatus.map((item, index) => (
        <Grid item xs={4} sm={3} md={2} key={index}>
          {item.name === "Risk" && (
            <RiskDetailBox title={item.name} detail={item.value} />
          )}
          {item.name !== "Risk" && (
            <InfoDetailBox title={item.name} detail={item.value} />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

export default StatusModule;
