import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { useTranslation } from "react-i18next";

const ImageSelector = ({ onImageSelect, PrevImage }) => {
  const [selectedImage, setSelectedImage] = useState(PrevImage || null);
  const [t] = useTranslation("global");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      onImageSelect(file); // Enviar el archivo seleccionado al componente padre
    }
  };

  return (
    <div>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="upload-image"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="upload-image">
        <IconButton component="span">
          <Avatar
            src={selectedImage || undefined}
            sx={{
              width: Spaces.Size_14,
              height: Spaces.Size_14,
              backgroundColor: selectedImage ? "transparent" : Colors.Secondary_50,
            }}
          >
            {selectedImage ? null : (
              <span
                style={{ fontSize: Spaces.Size_8, color: Colors.Secondary_900 }}
                className="material-symbols-outlined"
              >
                {t("signalProvider.photo_camera")}
              </span>
            )}
          </Avatar>
        </IconButton>
      </label>
    </div>
  );
};

export default ImageSelector;
