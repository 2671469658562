import React from "react";
import { Box } from "@mui/material";
import Svg4 from "../../assets/Svg/vector4.svg";
import Svg5 from "../../assets/Svg/vector5.svg";
import Svg6 from "../../assets/Svg/vector6.svg";
import Svg7 from "../../assets/Svg/Ellipse 9.svg";
import * as styles from "./Styles";

function DashboardBackground() {
  return (
    <Box sx={styles.backgroundDashboardContainer}>
      <Box sx={{width: "70vw", height: "85vh", position: "relative"}}>
      <img src={Svg6} alt="Background 4" style={styles.svg6} />
      <img src={Svg5} alt="Background 3" style={styles.svg5} />

        <img src={Svg4} alt="Background 1" style={styles.svg4} />
        <img src={Svg7} alt="Background 2" style={styles.svg7} />

      </Box>
    </Box>
  );
}

export default DashboardBackground;
