import PropTypes from "prop-types";
import { Select, MenuItem, Typography, FormHelperText } from "@mui/material";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";

const SlimSelector = ({
  title,
  value,
  handleChange,
  options,
  error,
  helperText,
  name,
}) => {
  const selectStyle = {
    fontFamily: TextVariables.Overline,
    borderRadius: Spaces.Size_2,
    width: "100%",
    padding: "0px",
    lineHeight: 1.5,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none", // Asegúrate de que el borde del contorno también esté desactivado
    },
    "& .MuiSelect-select": {
      padding: Spaces.Size_3, // Asegúrate de que el borde del contorno también esté desactivado
    },
    border: error
      ? `1px solid ${Colors.Error_700}`
      : `1px solid ${Colors.Secondary_700}`,
  };
  return (
    <div>
      <Select
        value={value}
        onChange={handleChange}
        sx={selectStyle}
        inputProps={{ name: name }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            sx={{ fontFamily: TextVariables.Body_4 }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};

SlimSelector.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SlimSelector;
