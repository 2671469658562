import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Svg1 from "../../assets/Svg/vector1.svg";
import Svg2 from "../../assets/Svg/vector2.svg";
import Svg3 from "../../assets/Svg/vector3.svg";
import Svg4 from "../../assets/Svg/vector7.svg";
import Svg5 from "../../assets/Svg/vector8.svg";
import * as styles from "./Styles";

function Background() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Aquí defines el breakpoint que quieres evaluar
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md")); // Aquí defines el breakpoint que quieres evaluar
  return (
    <Box>
      {!(isSmallScreen || isMediumScreen) && (
        <Box sx={styles.backgroundContainer}>
          <img src={Svg1} alt="Background 1" style={styles.svg1} />
          <img src={Svg2} alt="Background 2" style={styles.svg2} />
          <img src={Svg3} alt="Background 3" style={styles.svg3} />
        </Box>
      )}
      {(isSmallScreen || isMediumScreen) && (
        <Box sx={styles.backgroundContainer}>
          <img src={Svg4} alt="Background 1" style={styles.svg1_s} />
          <img src={Svg5} alt="Background 2" style={styles.svg2_s} />
        </Box>
      )}
    </Box>
  );
}

export default Background;
