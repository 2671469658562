import React, { useState } from "react";
import { Paper, InputBase, Box, TextField, InputAdornment } from "@mui/material";
import * as styles from "./Styles.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import {Spaces} from "../../../assets/Variables/SpaceVariables.js";

function SearchBoxComponent({handleSearchTerm, value, placeholderprops}) {
   
    return(
        <TextField
              sx={{
                width: "263px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: `1px solid ${Colors.Secondary_400}`,
                borderRadius: Spaces.Size_2,
                fontFamily: TextVariables.Caption_1,
                padding: "0px",
                "& .MuiInputBase-input": {
                  fontFamily: TextVariables.Overline,
                  width: "100%",
                  padding: Spaces.Size_3,
                  lineHeight: 1
                },
                "& .MuiOutlinedInput-root": {
                  width: "100%",
                },
              }}
              placeholder={placeholderprops ? placeholderprops : "Search"}
              onChange={handleSearchTerm}
              value={value}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="material-symbols-outlined">search</span>
                  </InputAdornment>
                ),
                sx: {
                  "& .MuiInputBase-input::placeholder": {
                    fontFamily: TextVariables.Caption_1,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Elimina el borde
                  },
                },
                autoComplete: "off",
              }}
            />
    );
}

export default SearchBoxComponent;
