import React, { useState } from 'react';
import { Tooltip, tooltipClasses, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as TextVariables from "../../../assets/Variables/TextVariables.js";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F5F8FF',
    color: '#15202A',
    fontSize: "12px",
    borderRadius: '10px',
    border: '1px solid #155EEF',
    padding: '16px',
    maxWidth: 'none',
    fontFamily: "Plus Jakarta Sans",
    fontStyle: "normal",
    fontWeight: "400"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#F5F8FF', // Color del borde de la flecha
    '&:before': {
      border: '1px solid #155EEF', // Establecer el borde para la flecha
    },
  },
}));

function TableToolTip({ title, children }) {
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <LightTooltip
      open={open}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      title={
        <Box display="flex" alignItems="center" justifyContent="center"
            sx={{
                maxWidth:'180px'
            }}>
          <Typography sx={{ flexGrow: 1, fontFamily: TextVariables.Caption_1 }}>
            {title}
          </Typography>
        </Box>
      }
      arrow
      placement="right"
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
      }}
    >
      {children}
    </LightTooltip>
  );
}

export default TableToolTip;
